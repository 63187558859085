import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div className='app-footer'>
      <div>
        <div className='copyright'>
          <span>Copyright © 2023 </span>
          <span className='copyright-strma-inc'>Strma Inc. </span>
          <span>All rights reserved.</span>
        </div>
        <div className='site-map'>
          <Link to="https://artist.strma.app">
            <div className='footerLink'>Upload Music (For Artists)</div>
          </Link>
          <Link to="/terms">
            <div className='footerLink'>Terms</div>
          </Link>
          <Link to="/advertise">
            <div className='footerLink'>Advertise</div>
          </Link>
          <Link to="/contact">
            <div className='footerLink'>Contact Us</div>
          </Link>
          <Link to="/faqs" className='last-item'>
            <div className='footerLink'>FAQs</div>
          </Link>
        </div>
      </div>
    </div>
  )
}
