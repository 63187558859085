import React, { useState, useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Loader } from "./../loader/loader"

export const Register = ({ props }) => {
    const [ State, setState ] = useState({ email:false, phone:false, selector:true })
    const H = useHistory()
    const email = useRef()
    const password = useRef()
    const city = useRef()
    const phone = useRef()

    const selectLoginType = () => {
        const t = document.querySelector(".loginWith_type_selector").value.toLowerCase()
        setState({ ...State, 
            [t]: !State[t], 
            selector: false
        })
    }
    
   
    const register = () => {
        const e = (State.email) ? email.current.value : phone.current.value
        const p = password.current.value
        const c = city.current.value
        
        props.register({ e, p, c })
    }

    useEffect(() => {
        if (props.user.id) setTimeout(() => {
            H.push("/")
        }, 3000)
    }, [props])

    return (
        <div className="sign_lgn_backg_cont">
                
            {
                props.user.id ? <Loader text={"Loading your account..."}/> 
                : 
                <div className="registeruser_cont_">
                    <h1 className="reg_user_hdr_land1_">Sign Up</h1><div className="explain_page_">Register below to Create Account.</div>
                    
                    <div style={{ paddingTop: 0 }} className="reguser_form_cornt_webapp">
                                                
                        <div className="fitm" style={{ display: State.selector ? "" : "none" }} >
                            <label className="frmlabel">How would you like to login</label>
                            <select className="loginWith_type_ reguserfrm_webapp loginWith_type_selector" type="contactSelector" onChange={selectLoginType}>
                                <option value="">Login with Email or Phone</option>
                                <option value="Email">Email</option>
                                <option value="Phone">Phone</option>
                            </select>
                        </div>


                        <div className="fitm" style={ (State.email) ? { display:"" }:{ display:"none" } }>
                            <label className="frmlabel">Email</label>
                            <input ref={email} type="email" className="reguserfrm_webapp email_userreg_landing" placeholder="Email" required=""/>
                        </div>
                        
                        <div className="fitm" style={ (State.phone) ? { display:"" }:{ display:"none" } }>
                            <label className="frmlabel">Phone</label>
                            <input ref={phone} type="tel" className="reguserfrm_webapp phone_userreg_landing" placeholder="Phone" required=""/>
                        </div>
                        
                        <div className="fitm">
                            <label className="frmlabel">Create Password</label>
                            <input ref={password} type="password" className="reguserfrm_webapp lastnme_sgnup" placeholder="Password" required=""/>
                        </div>

                        <div className="fitm">
                            <label className="frmlabel">City / Town</label>
                            <input ref={city} className="reguserfrm_webapp city_signup" placeholder="City/Town" required=""/>
                        </div>
                        
                    </div>
                    <div className="btn_container_reguser_webapp">
                        <div className="sign_btn_reguser_login" onClick={register}>Create</div>
                    </div> 
                </div>}
            <div onClick={H.goBack}> <button className="cls_sgn_lgn">Close</button> </div>   
        </div>
    )
}