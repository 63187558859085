import {
  getHomeData, togglePlaylist, next, prev, play, pause, download,
  usersignin, register, toggleShare, openPlaylistsWidget, addToQueue, 
  getLatest, getPopular, getSingle, getPlaylist, getArtist, showAd, updateSessionPlayCount,
  cleanData, getAlbum, pushToQueue, setQueueStatus, search, AdOff, getAds, flipads, updateMetric,
  setLibState, tabState, setMQueueState, deleteFromPlaylist, rawsongupdate,
  addSongToPlaylist, ready, getSongs, getAlbums, getSongsL, togglePlay, createPlaylist, toggleCreatePl,
  getPodcast, closeQueueState, openQueueState, resignin, toggleOptionsOn, toggleOptionsOff
} from "./../actions/action"

export const mapReduxProps = (state) => ({
  analytics: state.analytics,
  mixpanel: state.mixpanel,
  PLAY: state.play,
  homeData: state.homeData,
  popular: state.popular,
  latest: state.latest,
  data: state.data,
  search: state.search,
  queue: state.queue,
  user: state.user,
  library: state.library,
  tabs: state.tabs,
  mobileQueue: state.mobileQueue,
  options: state.options,
  share: state.share,
  addToPlaylist: state.addToPlaylist,
  createplaylist: state.createplaylist,
  ploptions: state.ploptions,
  ads: state.ads,
  createPlaylistWidget: state.createPlaylistWidget, 
  raw: state.raw,
  queueState: state.queueState
})

export const mapReduxActions = (dispatch) => ({
  // Auth:
  usersignin: (data) => dispatch(usersignin(data)),
  resignin: () => dispatch(resignin()),
  register: (data) => dispatch(register(data)),
  cleanData: () => dispatch(cleanData()),
  // Page Data:
  home: () => dispatch(getHomeData()),
  getAlbum: (id) => dispatch(getAlbum(id)),
  getPlaylist: (id) => dispatch(getPlaylist(id)),
  getArtist: (id) => dispatch(getArtist(id)),
  getSingle: (id) => dispatch(getSingle(id)),
  getLatest: () => dispatch(getLatest()),
  getPopular: () => dispatch(getPopular()),
  getSongs: (time) => dispatch(getSongs(time)),
  getSongsL: (time) => dispatch(getSongsL(time)),
  getAlbums: (time) => dispatch(getAlbums(time)),
  getPodcast: (data) => dispatch(getPodcast(data)),
  // Player:
  pause: () => dispatch(pause()),
  NEXT: () => dispatch(next()),
  PREV: () => dispatch(prev()),
  play:(song) => dispatch(play(song)),
  ready:(d) => dispatch(ready(d)),
  download:(id) => dispatch(download(id)),
  playpause: () => dispatch(togglePlay()),
  // Update Metric:
  updateMetric: (id, type) => dispatch(updateMetric(id, type)),
  // Ads:
  adOff: () => dispatch(AdOff()),
  showAd: () => dispatch(showAd()),
  getAds: () => dispatch(getAds()),
  flipads: () => dispatch(flipads()),
  updateSessCount: () => dispatch(updateSessionPlayCount()),
  // Options & Menu:
  pushToQueue: () => dispatch(pushToQueue()),
  addToQueue: (id) => dispatch(addToQueue(id)),
  openPlaylistsWidget: (id) => dispatch(openPlaylistsWidget(id)),
  addSongToPlaylist: (data) => dispatch(addSongToPlaylist(data)),
  setQueueStatus: () => dispatch(setQueueStatus()),
  toggleOptionsOn: (song) => dispatch(toggleOptionsOn(song)),
  toggleOptionsOff: (song) => dispatch(toggleOptionsOff(song)),
  toggleShare: (id, type) => dispatch(toggleShare(id, type)),
  togglePlaylist: (id, type) => dispatch(togglePlaylist(id, type)),
  deleteFromPlaylist: (data) => dispatch(deleteFromPlaylist(data)),
  createPlaylist: (name) => dispatch(createPlaylist(name)),
  toggleCreatePl: () => dispatch(toggleCreatePl()),
  rawsongupdate: (list) => dispatch(rawsongupdate(list)),
  closeQueueState: () => dispatch(closeQueueState()),
  openQueueState: () => dispatch(openQueueState()),
  // Search:
  SEARCH: (e) => dispatch(search(e)),
  // Library:
  setLibState: () => dispatch(setLibState()),
  tabState: (e) => dispatch(tabState(e)),
  setMQueueState: (e) => dispatch(setMQueueState(e))
})