import React from "react"
import play from "../../../assets/play.png"
import pause from "../../../assets/pause.png"
import prevv from "../../../assets/prevv.png"
import nextt from "../../../assets/nextt.png"
import { buttonEffect, songIteratorHandler } from "../helper"

export const MobileMediaControls = ({ props }) => {
  const isPaused = props.PLAY.paused
  const iteratorIcon = { next: nextt, prev: prevv }
  const playPauseClasses = `${isPaused ? "pause" : "play"} playpausetoggle`

  const mobilePlayPauseHandler = () => {
    buttonEffect(".playpausetoggle")
    props.queue.music[0] && setTimeout(props.playpause, 100)
  }
  
  const PlayPauseButton = () => (
    <img src={isPaused ? play : pause} className={playPauseClasses}
      onClick={mobilePlayPauseHandler} alt=""/>
  )

  const IteratorButton = ({ title }) => (
    <div className="iconcontainerpinn">
      <img className={`${title} iterators_`}
        src={iteratorIcon[title]}
        onClick={() => songIteratorHandler(`.${title}`, title, props)}
        alt=""/>
    </div>
  )

  return <div className="mobile-media-controls">
      <IteratorButton title="prev"/>
      <PlayPauseButton/>
      <IteratorButton title="next"/>
  </div>
}
