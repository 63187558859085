import React from "react"

export const PlayerMusicControls = ({
  time, slider, duration, durationhold,
  switchToPreviousSong, switchToNextSong,
  PlayPauseButton, IteratorButton, durationBar
}) => {
  return <div className="music_controls">
      <div className="media-controls">
          <div className="iconcontainerpit" onClick={switchToPreviousSong}>
              <div className="iconcontainerpinn">
                  <IteratorButton title="prev"/>
              </div>
          </div>
          <div className="iconcontainerp">
              <div className="iconcontainerpinnpp">
                  <PlayPauseButton title="play" />
                  <PlayPauseButton title="pause" />
              </div>
          </div>
          <div className="iconcontainerpit" onClick={switchToNextSong}>
              <div className="iconcontainerpinn">
                  <IteratorButton title="next"/>
              </div>
          </div>
      </div>
      <div className="duration_barcontainer">
          <div ref={time} className="player-current-time">0.00</div>
          <div ref={durationhold} className="player-duration-bar duration_bar_desktop" onClick={(e) => durationBar(e)}>
              <div ref={slider} className="player-duration-slider-dot"></div>
          </div>
          <div ref={duration} className="player-duration-time">0.00</div>
      </div>
  </div>
}