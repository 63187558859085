import React from "react"
import _ from "lodash"

export const AddToPlaylistW = ({ props }) => {
    const songid = props.addToPlaylist.songid

    return (
        <div className="contextoverly">
            <div className="contextoverlyhold">
                <div className="context_menu">
                    <div className="context_menu_hdr">Add song to Playlist</div>
                    <div className="ctx_playlistitm_hld">
                        <div className="ctx_playlistitm_hld_inn">
                            {Object.values(props.user.playlists).map((a, i) => (a.playlistName) && (
                                <div key={i} className="cxtholdpl" onClick={() => props.addSongToPlaylist({
                                    songid,
                                    uid: props.user.id,
                                    plid: _.findKey(props.user.playlists, { "playlistName":a.playlistName })
                                })}>
                                    <span className="cxt_menu_itempl">{a.playlistName}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="closectxhold" onClick={() => props.togglePlaylist(null)}><span className="ctxclosetxt">Close</span></div>
                </div>
            </div>
        </div>
    )
}