import React from 'react'

export const Loader = ({text}) => {
    
    return (
        <div className="loadpending">
            <div className="loading">
                <div className="loader">Loading...</div>
            </div>
            <div className="loaderpend">{text}</div>
        </div>
    )
}
