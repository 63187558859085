import React, { useEffect } from "react"
import clipboardimg from "./../../assets/link.png"
import whatsappimg from "./../../assets/whatsapp.png"
import $ from "jquery"

export const Share = ({ props }) => {
    const whatsAppUrl = `https://api.whatsapp.com/send?text=https://app.strma.app/${props.share.type}/${props.share.id}`
    const LinkReducer = (type) => {
        switch (type) {
            case "whatsapp":
                props.toggleShare(null, null)
                return 
            case "toclipboard":{
                let queryid = props.share.id.toString().split(" ").join("%20")

                for (var i=0; i<5; i++){
                    // Create elemnt
                    let clipBoardUrl = `https://app.strma.app/${props.share.type}/${queryid}`
                    
                    let k = document.createElement("input")
                    k.style.opacity = "0"; k.style.position = "fixed"; k.style.top = "0";  k.value = clipBoardUrl; k.className = "clipbrditem"
                    // Append
                    $("body").append( k )
                    // Copy to clipboard
                    document.execCommand("copy", false)
                    k.select()
                }               
                $(".clipbrditem").remove()
                props.toggleShare(null, null)
                return
            }
            case "close":
                $(".clipbrditem").remove()
                props.toggleShare(null, null)
                return 
            default: 
                return
        }
    }

    useEffect(() => {}, [])
    
    return (
        <div className="sharelinkcontainer">
            <div className="sharelinkwidget">
                <div className="sharelinkhdr">Share Music</div>
                <div className="sharelinkpd">Spread this experience by sharing with your network!</div>
                <div className="sharelinkoptions">
                    <a style={{ textDecoration:"none" }} href={`${whatsAppUrl}`} target="_blank" rel="noreferrer">
                        <div style={{ marginRight: "65px" }} className="sharelinkitem" onClick={() => LinkReducer("whatsapp")}>
                            <img src={whatsappimg} className="sharelinkicon" alt=""/>
                            <div className="sharelinktext">Whats App</div>
                        </div>
                    </a>
                    
                    <div className="sharelinkitem">
                        <img src={clipboardimg} className="sharelinkicon" onClick={() => LinkReducer("toclipboard")} alt="albumArtwork"/>
                        <div className="sharelinktext">Copy Link</div>
                    </div>
                </div>
                <button className="sharelinkclose" onClick={() => LinkReducer("close")}>Close</button>
            </div>
        </div>
    )
}
