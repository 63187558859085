import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"
import { LazyLoadLatest } from "../LazyLoaded/LazyLoadLatest"

const playStyles = {
  container:{ 
      play: { border: "0px solid rgb(181 16 46)", background: "rgb(158 0 36)" }, 
      off: { background:"", border:"" },
  },
}

export const MusicContainer = ({ music, props, type }) => {
  const { singles, albums } = props.latest
  const contentContainer  = {
    albums: ({ children, url }) => (<Link to={url}>{children}</Link>),
    singles: ({ children }) => (<>{ children }</>)
  }

  const getItemId = ({ collection, authorKey, titleKey, author, title }) => _.findKey(collection, {
    [authorKey]: author, 
    [titleKey]: title
  })

  const latestToggleOption = (song, artist) => props.toggleOptionsOn({
    id: _.findKey(props.latest.singles, {
      songTitle: song, 
      artist: artist
  }) }, "single")

  const contentTemplate = ({ artist, title, artwork, index }) => {
    const id = getItemId({
      collection: type === "albums" ? albums : singles,
      authorKey: type === "albums" ? "Artist" : "artist",
      titleKey: type === "albums" ? "albumTitle" : "songTitle",
      author: artist,
      title
    })
    const url = type === "albums" ? `/album/${id}` : "/"
    const mapKey = title + index + artist
    const ItemContainer = contentContainer[type]
    const styles = type === "singles" ? (id === props.PLAY.song.id ? playStyles.container.play : playStyles.container.off) : {}
    const optionsToggle = type === "singles" ? () => latestToggleOption(title, artist) : () => {}

    return (
      <ItemContainer url={url} key={id + mapKey}>
        <div className="latest-music-song-item latest-music-lazy-load" id="song" 
          key={mapKey} style={styles} onClick={optionsToggle}>
              <div className="latest-music-songartwork">
                  <img className="latest-music-songartwork-img" src={artwork} alt="albumArtwork"/>
              </div>
              <div className="latest-music-song-meta">
                  <div className="track-song-name song-play-name">{title}</div>
                  <div className="latest-music-song-artist song-play-artist">{artist}</div>
              </div> 
        </div>
      </ItemContainer>
    )
  }

  return (
    <div className="latest-music-container">
      {music ? music.map((item, index) => (
        contentTemplate({
          artist: type === "albums" ? item.Artist : item.artist,
          title: type === "albums" ? item.albumTitle : item.songTitle,
          artwork: type === "albums" ? item.albumArtwork : item.image,
          index
        })))
        : 
        <LazyLoadLatest/>
      }
    </div>
  )
}
