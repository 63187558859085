import React from 'react'
import { searchIconStyles } from '../constants/constants'

export const DefaultMobileSearchPage = () => (
  <div className="placeholder_logo_container_srch">
      <svg className="srchpage_menu_icn" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.96 90.06">
          <defs><style/></defs>
          <title>search</title>
          <ellipse style={searchIconStyles.cs1} cx="38.91" cy="38.22" rx="36.41" ry="35.72"></ellipse>
          <path style={searchIconStyles.cs2} d="M577,992.45a27.87,27.87,0,0,0,7.12-6.75l17.25,15.78s-.49,6.71-6.69,7.52Z" transform="translate(-510.45 -918.94)"></path>
      </svg>
      <span>Search any song, artist <br/> or album.</span>
  </div>
)
