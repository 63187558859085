
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import _ from "lodash"

const Lazy = () => (
    <div>
        {[...Array(8)].map(( a, i) => (
            <Link to={`/artist/${i}`} key={i}>
                <article className="artist_item_popular_lazy">
                    <div className="img_popular_artist_lazy"></div>
                    <div className="artist_name_lazy"></div>
                </article>
            </Link>
        ))}
    </div>
)

const Dancehall = ({ props }) => {
    const items = _.filter(Object.values(props.popular), [ "genre", "Zim-Dancehall" ])
    return (
        <div>
            {items.map((a) => (
                <Link to={`/artist/${a.id}`} key={a.id}>
                    <article className="artist_item_popular_lazy">
                        <div className="img_popular_artist_lazy">
                            <img src={`${a.artistImage}`} className="imagepop" alt=""/>
                        </div>
                        <div className="artist_name_pop">{a.artistName}</div>
                    </article>
                </Link>
            ))}
        </div>
    )
}

const Other = ({ props }) => {
    const items = _.filter(Object.values(props.popular), (a) => a.genre !== "Zim-Dancehall" )    
    return (
        <div>
            {items.map((a) => (
                <Link to={`/artist/${a.id}`} key={a.id}>
                    <article className="artist_item_popular_lazy">
                        <div className="img_popular_artist_lazy">
                            <img src={`${a.artistImage}`} className="imagepop" alt=""/>
                        </div>
                        <div className="artist_name_pop">{a.artistName}</div>
                    </article>
                </Link>
            ))}
        </div>
    )
}


export const Popular = ({ props }) => {
    useEffect(() => {
        props.getPopular()
    }, [])

    return (
        <div className="popular_cont_">
            <Helmet>
                <meta className="metadescript" name="description" content={"Discover popular Zim artists Strma. In Zim-Dancehall, Sungura, Hip Hop."}/>
                <meta className="metakeywords" name="keywords" content="Strma, zimbabwe, music, online, listen, streaming, play, digital, album, artist, playlist, strmaio, strma.app, web player, zim-music"/>
                <meta className="metaurl" property="og:url" content={"https://player.strma.app/popular"}/>
                <meta className="metaogtitle" property="og:title"  content={"Discover popular Zim artists Strma. In Zim-Dancehall, Sungura, Hip Hop."} />
                <meta className="metaogimage" property="og:image" content="./../../assets/feature.png"  />
            </Helmet>
            <section className="genre_container">
                <h1 className="genre_hdr_l">Dancehall</h1>
                <div className="dancehall_artists_cont_">
                    {props.popular ? <Dancehall props={props} type={"dancehall"}/> : <Lazy/>}
                </div>
            </section>

            <section className="genre_container">
                <h1 className="genre_hdr_l">Popular Music</h1>
                <div className="other_artists_cont_">
                    {props.popular ? <Other props={props} type={"other"}/> : <Lazy/>}
                </div>
            </section>
        </div>
    )
}