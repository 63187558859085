import React from "react"
import { useHistory } from "react-router-dom"
import { ToggleOptionsHandler } from "../helper"
import { DownChevronIcon } from "./downChevronIcon"

export const MobileQueueNowPlayingSection = ({ props }) => {
  const history = useHistory()
  const queueTopContainerStyles = { display: "grid", gridTemplateColumns: "15% 85%" }
  return (
    <>
      <div className="fade-player"/>
      <div className="now-playing-mobile-section">
          <div onClick={props.closeQueueState} style={queueTopContainerStyles}>
              <DownChevronIcon/>
              <span className="now-playing-mobile-section-text">Now Playing</span>
          </div>
          <div className="song-container-options now-playing-mobile-section-options">
            <p className="now-playing-mobile-section-options-dots"
              onClick={() => ToggleOptionsHandler(history, props)}>...</p>
          </div>
      </div>
    </>
  )
}