import React from "react"
import musicPlayerQueueImg from "./../../../assets/queue.png"

export const DesktopPlayerQueueSection = ({ props, audio }) => {
  return <>
      <div className="player-queue-area" onClick={props.setQueueStatus}>
          <div className="player-queue-area-icon">
              <img src={musicPlayerQueueImg} className="player-queue-area-img" alt="player-queue-area-img"/>
              <p className="player-queue-area-text">Queue</p>
          </div>
      </div>
      <audio className="aud" ref={audio}/>
  </>
}