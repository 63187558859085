
import React from "react"
import { LandingPageAlbums } from "./LandingPageAlbums"
import { LandingPageLazyLoadedAlbums } from "../LazyLoaded/LandingPageLazyLoadedAlbums"

export const Albums = ({ props }) => {
    return (
        <div className="albumshm-container-h">
            <div className="sectionhdr">
                <div className="landing-page-section-header">Albums</div>
            </div>
            <div className="strma_album landing-page-playlists-section">
                <div className="landing-page-playlists-body">
                    {props.albums ? <LandingPageAlbums props={props}/> : <LandingPageLazyLoadedAlbums/>}
                </div>
            </div>
        </div>
    )
}