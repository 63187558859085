
import React from "react"
import { useHistory } from "react-router-dom"
import { HomePageLazyLoadedSingles } from "../LazyLoaded/HomePageLazyLoadedSingles"
import { LandingPageSingles } from "./landngPageSingles"

export const Singles = ({ props }) => {
    const H = useHistory()
    const ShowMore = () => H.push("/latest/singles")
    const renderSongsWithLazyLoad = () => props.homeData.songs ? <LandingPageSingles props={props} /> : <HomePageLazyLoadedSingles />
    return (
        <div className="sinlgles-container-h" data-name="singles">
            <div className="new_tracks_ landing-page-playlists-section">
                <div className="sectionhdr">
                    <div className="landing-page-section-header">Latest Tracks</div>
                    <button
                        className="showmore"
                        onClick={ShowMore}
                   >Show More</button>
                </div>
                <div className="newtracks_body">{renderSongsWithLazyLoad()}</div>
            </div>
        </div>
    )
}