import React from "react"
import { headerChevronStyles } from "../../constants/constants"
import { AlbumsResults } from "./albumResults"
import { ArtistResults } from "./artistResults"
import { SongsResults } from "./songResults"
import { TopResults } from "./topResults"

export const SectionHeaderTitle = ({ title }) => (
    <h1 className="search-results-header">
        <span>{title}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
            aria-hidden="true"
            style={headerChevronStyles}
        ><path d="M19.817 61.863c1.48 0 2.672-.515 3.702-1.546l24.243-23.63c1.352-1.385 1.996-2.737 2.028-4.443 0-1.674-.644-3.09-2.028-4.443L23.519 4.138c-1.03-.998-2.253-1.513-3.702-1.513-2.994 0-5.409 2.382-5.409 5.344 0 1.481.612 2.833 1.739 3.96l20.99 20.347-20.99 20.283c-1.127 1.126-1.739 2.478-1.739 3.96 0 2.93 2.415 5.344 5.409 5.344Z" />
        </svg>
    </h1>
)

export const DesktopSearchResults  = ({ props }) => {
  return (
      <div className="results-container">
          <div className="search-results-body">
            <div className="artist-search-results-section">
                <SectionHeaderTitle title='Top Results'/>
                <div className="search-section-results-container">{
                    props.search.data.topResults && <TopResults props={props} />
                }</div>
            </div>
            <div className="artist-search-results-section">
                <SectionHeaderTitle title='Artist'/>
                <div className="search-section-results-container">{
                    props.search.data.artists && <ArtistResults props={props} />
                }</div>
            </div>
            <div className="albums-search-results-section">
                <SectionHeaderTitle title='Albums'/>
                <div className="search-section-results-container">{
                    props.search.data.albums && <AlbumsResults props={props} />
                }</div>
            </div>
            <div className="songs-search-results-section">
                <SectionHeaderTitle title='Songs'/>
                <div className="search-section-results-container">{
                    props.search.data.songs && <SongsResults props={props} />
                }</div>
            </div>
          </div>

      </div>
  )
}
