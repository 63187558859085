import axios from "axios"
import $ from "jquery"
import { mixpanel } from "../analytics"
import { BACKEND_URLS } from "../constants/constants"
import * as TYPES from "./types"

// Get Home Data Functions:
export function getHomeData() {
    return async function (dispatch) {
        var width = window.innerWidth
        var ifMobile = (navigator.userAgent.includes("android") && width < 600) || navigator.userAgent.includes("iPhone")
        var songsByDevice = ifMobile ? 4 : 16

        const playlists = await axios.get(BACKEND_URLS.playlists).then(response => response).catch(error => error)
        const songs = await axios.get(BACKEND_URLS.songs(0, songsByDevice)).then(response => response).catch(error => error)
        const albums = await axios.get(BACKEND_URLS.albums(0, 10)).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.HOME,
            payload: {
                data: {
                    playlists: playlists.data, songs: songs.data, albums: albums.data
                },
            },
        })

    }
}

// Get Podcast:
export function getPodcast({ id }) {
    return async function (dispatch) {
        const podcast = await axios.get(BACKEND_URLS.getPodcast(id)).then(response => response.data).catch(error => error)

        return dispatch({
            type: TYPES.PLAYLIST,
            payload: {
                data: podcast
            }
        })
    }
}

// Get Latest:
export function getPopular() {
    return async function (dispatch) {
        const popular = await axios.get(BACKEND_URLS.popular).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.POPULAR,
            payload: {
                data: popular.data
            }
        })
    }
}

// Get Latest:
export function getLatest() {
    return async function (dispatch) {
        const albums = await axios.get(BACKEND_URLS.albums(0, 25)).then(response => response).catch(error => error)
        const singles = await axios.get(BACKEND_URLS.songs(0, 25)).then(response => response).catch(error => error)
        console.log({ albums, singles })
        return dispatch({
            type: TYPES.LATEST,
            payload: {
                data: { albums: albums.data, singles: singles.data }
            }
        })
    }
}

// Get Songs:
export function getSongs(time) {
    return async function (dispatch) {
        const singles = await axios.get(BACKEND_URLS.songs(time, 16)).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.SONGSH,
            payload: {
                data: singles.data 
            }
        })
    }
}

// Get Songs:
export function getSongsL(time) {
    return async function (dispatch) {
        const singles = await axios.get(BACKEND_URLS.songs(time, 25)).then(response => response).catch(error => error)
        $(".app-body-main").animate({ scrollTop: 0 }, 400)
        $(".new_release_cont_ ").animate({ scrollTop: 0 }, 400)
        updateSessionPlayCount()
        return dispatch({
            type: TYPES.SONGSL,
            payload: {
                data: singles.data 
            }
        })
    }
}

// Get Albums:
export function getAlbums(time) {
    return async function (dispatch) {
        const albums = await axios.get(BACKEND_URLS.albums(time, 25)).then(response => response).catch(error => error)
        updateSessionPlayCount()

        return dispatch({
            type: TYPES.ALBUMS,
            payload: {
                data: albums.data 
            }
        })
    }
}


// Clear Data
export function cleanData() {
    return function (dispatch) {
        return dispatch({
            type: TYPES.CLEAN,
            payload: {
                data: ""
            },
        })
    }
}

// Get Album:
export function getAlbum(id) {
    return async function (dispatch) {
        const album = await axios.get(BACKEND_URLS.getAlbum(id))
            .then(response => response).catch(error => error)
        return dispatch({
            type: TYPES.ALBUM,
            payload: {
                data: album.data
            }
        })
    }
}

// Get Playlist:
export function getPlaylist(id) {
    return async function (dispatch) {
        const playlist = await axios.get(BACKEND_URLS.getPlaylist(id))
            .then(response => response)
            .catch(error => error)

        return dispatch({
            type: TYPES.PLAYLIST,
            payload: {
                data: playlist.data
            }
        })
    }
}

// Get Artist:
export function getArtist(id) {
    return async function (dispatch) {
        const artist = await axios.get(BACKEND_URLS.getArtist(id)).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.ARTIST,
            payload: {
                data: artist.data
            }
        })
    }
}

// Get Single:
export function getSingle(id) {
    return async function (dispatch) {
        const song = await axios.get(BACKEND_URLS.getSong(id)).then(response => response).catch(error => error)
        
        return dispatch({
            type: TYPES.SINGLE,
            payload: {
                data: song.data
            }
        })
    }
}

// Set Queue State:
export function setQueueStatus() {
    return async function (dispatch) {
        dispatch({
            type: TYPES.QUEUE,
            payload: "queuedata"
        })
    }
}

// Toggle Play
export function togglePlay() {
    return async function (dispatch) {
        dispatch({
            type: TYPES.PLAYPAUSE,
            payload: "PLAYPAUSE"
        })
    }
}

// Set Option State:
export function toggleOptionsOn(song) {
    return async function (dispatch) {
        const { artist, songTitle } = song
        const songId = song.id ? song.id : await GetSongIdIfUndefined({ artist, songTitle })
        dispatch({
            type: TYPES.OPTIONS_ON,
            payload: songId 
        })
    }
}

// Set Option State:
export function toggleOptionsOff() {
    return async function (dispatch) {
        dispatch({
            type: TYPES.OPTIONS_OFF
        })
    }
}

// Set Option State:
export function togglePlaylist() {
    return async function (dispatch) {
        dispatch({
            type: TYPES.TOGGLEPLAYLIST,
            payload: "TOGGLEPLAYLIST"
        })
    }
}

export function getAds(){
    return async function (dispatch) {
        const ads = await axios.get(BACKEND_URLS.ads()).then(response => response).catch(error => error)
        
        return dispatch({
            type: TYPES.ADS,
            payload: ads.data
        })

    }
}

// Flip Ads:
export function flipads(){
    return async function (dispatch) {
        return dispatch({
            type: TYPES.FLIPADS,
            payload: "FLIPADS"
        })

    }
}

// Sign In:
export function usersignin(data) {
    return async function (dispatch) {
        const id =  window.sessionStorage.getItem("di")
        let user = {}
        // Replace with JWT token only save token, send it and get back data
        if (!id) {
            user = await axios.post(BACKEND_URLS.signin(), data).then(response => response).catch((err) => {
                mixpanel.track("error", {
                    type: "authentication",
                    message: err.message
                })
            })
            if (user?.data?.status === "success") {
                window.sessionStorage.setItem("di",  user.data.user.id)
                window.sessionStorage.setItem("pls",  JSON.stringify({
                    [user.data.user.id]: user.data.user
                }))
                mixpanel.track("user_signin")
            }
            else {
                alert("User Sign In Failed! Please try again.")
            }
        }
        else {
            const data =  JSON.parse(window.sessionStorage.getItem("pls"))[id]
            user = {
                data: {
                    user: data
                }
            }
        }
        if (user) return dispatch({
            type: TYPES.AUTHENTICATION,
            payload: user.data.user
        })
    }
}

// Sign In:
export function resignin() {
    return async function (dispatch) {
        const id =  window.sessionStorage.getItem("di")
        if (id) {
            let user = {}
            // Replace with JWT token only save token, send it and get back data
            const data = JSON.parse(window.sessionStorage.getItem("pls")) ?.[id]
            user = {
                data: {
                    user: data
                }
            }
            if (user) return dispatch({
                type: TYPES.RE_AUTHENTICATION,
                payload: user.data.user
            })
        }
    }
}

// User Register:
export function register(data) {
    return async function (dispatch) {
        const user = await axios.post(BACKEND_URLS.register(), data).then(response => response).catch((err) => {
            mixpanel.track("error", {
                type: "authentication",
                message: err.message
            })
        })

        if (user.data?.status === "success") {
            // Track
            mixpanel.track("new_account")
            return dispatch({
                type: TYPES.AUTHENTICATION,
                payload: user.data.user
            })
        } else {
            alert("Account registration Failed! Please try again.")
        }
    }
}

// Close Queue State
export const closeQueueState = function(){ 
    return async function (dispatch) { 
        return dispatch({
            type: TYPES.CLOSE_QUEUE_STATE
        })
    }
}

// Open Queue State
export const openQueueState = function(){ 
    return async function (dispatch) { 
        return dispatch({
            type: TYPES.OPEN_QUEUE_STATE
        })
    }
}


// Toggle Playlist Widget: 
export function toggleCreatePl() {
    return async function (dispatch) {
        return dispatch({
            type: TYPES.TOGGLECREATEPL,
            payload: "TOGGLECREATEPL"
        })
    }
}

// Create Playlist: 
export function createPlaylist(data) {
    return async function (dispatch) {
        const create = await axios.post(BACKEND_URLS.createPl(data["uid"], data["plname"]), data).then(response => response).catch(err => err)

        return dispatch({
            type: TYPES.CREATEPL,
            payload: create.data
        })
    }
}

// Delete Playlist:
export function deletePlaylist(data) {
    return async function (dispatch) {
        const deletepl = await axios.post(BACKEND_URLS.deletePlaylist(data["plid"], data["uid"]), data).then(response => response).catch(err => err)

        return dispatch({
            type: TYPES.DELETEPL,
            payload: deletepl.data
        })
    }
}

// Delete Song from PL: --done
export function deleteFromPlaylist(data) {
    return async function (dispatch) {
        const deletesong = await axios.post(BACKEND_URLS.delFromPl(data["uid"], data["plid"], data["songid"]), data).then(response => response).catch(err => err)
        return dispatch({
            type: TYPES.DELETEFROMPL,
            payload: deletesong.data
        })
    }
}

// Open Add to playlists Widget: --done
export function openPlaylistsWidget(id) {
    return async function (dispatch) {

        return dispatch({
            type: TYPES.OPENPLAYLISTSWIDGET,
            payload: id
        })
    }
}

// Set Queue State: --done
export function addSongToPlaylist(data) {
    return async function (dispatch) {
        const added_playlist = await axios.post(BACKEND_URLS.addToPl(data["uid"], data["plid"], data["songid"]))
            .then(response => response).catch(error => error)
        return dispatch({
            type: TYPES.ADDINGDONE,
            payload: added_playlist.data
        })
    }
}

// Set Queue State:
export function addToQueue(id) {
    return async function (dispatch) {
        const song = await axios.get(BACKEND_URLS.getSong(id)).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.ADDTOQUEUE,
            payload: { ...song.data, id: id }
        })
    }
}

// Show Ad:
export function showAd(){
    return async function (dispatch) {
        return dispatch({
            type: TYPES.AD,
            payload: ""
        })
    }
}

export function AdOff () {
    return async function (dispatch) {

        return dispatch({
            type: TYPES.AD_OFF,
            payload: ""
        })
    }
}

// Update Song Play Count:
export function updateSessionPlayCount(){
    const count = (window.sessionStorage.getItem("PC")) ? parseInt(window.sessionStorage.getItem("PC")) : 0
    
    // if(count==5){
    //     window.sessionStorage.setItem('PC', 0)
    //     return dispatch({
    //         type: TYPES.ADSHOWREADY,
    //         payload: ''
    //     })
    // }
    if (count>0){
        window.sessionStorage.setItem("PC", count+1)
    }
    else {
        window.sessionStorage.setItem("PC", 1)
    }
}

const GetSongIdIfUndefined = async({ artist, title }) => {
    let result = {}
    if (artist && title) {
        result = await axios
            .get(BACKEND_URLS.searchByArtistnameAndTitle({ artist, songTitle: title }))
            .then(response => response)
            .catch(error => error)
    }
    return {
        id: result?.data?.id ? result?.data?.id : null
    }
}
 
// Play: --done
export function play({ id, artist, songTitle }) {
    return async (dispatch) => {
        const songId = id ? id : await GetSongIdIfUndefined({ artist, title: songTitle })
        const song = await axios.get(BACKEND_URLS.getSong(songId))
            .then(response => response)
            .catch(error => error)
        updateSessionPlayCount()
        return dispatch({
            type: TYPES.PLAY,
            payload: { ...song.data, id: id }
        })
    }
}

// Ready: --done
export function ready(audio) {
    return async function (dispatch) {

        return dispatch({
            type: TYPES.READY,
            payload: audio
        })
    }
}

// Prev: --donw
export function prev() {
    return async function (dispatch) {
        updateSessionPlayCount()
        return dispatch({
            type: TYPES.PREV,
            payload: "prev"
        })
    }
}

// Next: --done
export function next() {
    return async function (dispatch) {
        updateSessionPlayCount()
        return dispatch({
            type: TYPES.NEXT,
            payload: "next"
        })
    }
}

// Pause:
export function pause() {
    return async function () {
        // const user = await axios.post(BACKEND_URLS.signin(), data).then(response=>response).catch(err=>err)

        // return dispatch({
        //     type:SIGNIN,
        //     payload:user.data
        // })
    }
}

// Download:
export function download(id) {
    return async function (dispatch) {
        const getsong = await axios.get(BACKEND_URLS.getSong(id)).then(response => response).catch(err => err)
        const song = getsong.data
        const filename = `${song.songTitle} - ${song.artist} (download songs at player.strma.app).mp3`

        // Facebook Events:
        // fbq('trackCustom', 'songDownload', {artist: song.artist, songTitle: song.songTitle, id: id })

        // Update Download Metric:
        await axios.get(BACKEND_URLS.updateSongMetric("d", id)).then(response => response).catch(error => error)

        const request = new XMLHttpRequest()
        request.open("GET", song["songURL"], true)
        request["responseType"] = "blob"
        request.onload = () => {
            const blob = request["response"]
            const link = document["createElement"]("a")
            link["href"] = URL["createObjectURL"](blob)
            link["download"] = filename
            link.click()
        }

        request.send()

        return dispatch({
            type: TYPES.OPTIONS_ON,
            payload: id
        })
    }
}

// Update Raw Song List
export function rawsongupdate(list) {
    return async function (dispatch) {
        
        return dispatch({
            type: TYPES.RAWUPDATE,
            payload: list
        })
    }
}


// Update Metric:
export function updateMetric(id, type){
    return async function () {
        await axios.get(BACKEND_URLS.updateSongMetric(type, id)).then(response => response).catch(error => error)
    }
}

window.axios = axios














// Set Option State:
export function toggleShare(ID, type) {
    return async function (dispatch) {
        dispatch({
            type: TYPES.SHARE,
            payload: { id: ID, type: type }
        })
    }
}

// Set Queue State:
export function setMQueueState() {
    return async function (dispatch) {
        dispatch({
            type: TYPES.MQUEUE,
            payload: "queuedata"
        })
    }
}

// Make Search
export function search(query) {
    return async function (dispatch) {
        const result = await axios.get(BACKEND_URLS.search(query)).then(response => response).catch(error => error)

        return dispatch({
            type: TYPES.SEARCH,
            payload: {
                data: result.data
            }
        })
    }
}



// Set Library State
export function setLibState() {
    return async function (dispatch) {
        // Handle ASYNC search here:
        return dispatch({
            type: TYPES.LIBRARY,
            payload: {
                data: null
            }
        })
    }
}

// Set Tab State
export function tabState(page) {
    return async function (dispatch) {
        // Handle ASYNC search here:
        return dispatch({
            type: TYPES.TABS,
            payload: {
                data: page
            }
        })
    }
}

export const pushToQueue = function (song) {
    return async function (dispatch) {
        return dispatch({
            type: TYPES.PUSHTOQUEUE,
            payload: {
                data: song
            }
        })
    }
}




























