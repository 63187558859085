import React from "react"
import icon from "./../../assets/note.png"
import { MusicBars } from "./../helpers/musicBars"
import { SongContainerStyles } from "../constants/constants"

export const SongContainer = ({ song, props, withDates, additionalClasses, withMetrics }) => {
    const songIsPlaying = props.PLAY.song.id && (props.PLAY.song.id === song.id)
    const containerStyle = songIsPlaying ? SongContainerStyles.container.play : SongContainerStyles.container.off

    return (
        <React.Fragment>
            <div className={`song-container ${additionalClasses}`} key={song.id} style={containerStyle}>
                <div className="song-container-meta" onClick={() => props.play(song)}>
                    <div className="song-container-meta-inner">
                        <span className="song-container-meta-span">
                            {songIsPlaying && <MusicBars />}
                            {!songIsPlaying && <img src={icon} className="song-container-icon" alt=""/>}
                        </span>
                    </div>
                    <div className="song-container-meta-text">
                        <span className="song-container-title-container">
                            <div className="song-container-meta-text-details song-play-name">{song.songTitle}</div>
                        </span>
                        <span className={`song-container-artist-container ${withDates ? "adjusted-for-date":""}`}>
                            <div className="song-container-meta-text-details song-play-artist">{song.artist}</div>
                        </span>
                        {withDates && (
                            <span className="song-container-date-container">
                                <div className="song-container-meta-text-details song-play-artist">
                                    {new Date(song.timeStamp).toDateString()}
                                </div>
                            </span>
                        )}
                        {withMetrics && (
                            <>
                                <span className="song-container-streams-container">
                                    <div className="song-container-meta-text-details">{song.streamCount}</div>
                                </span>
                                <span className="song-container-downloads-container">
                                    <div className="song-container-meta-text-details">{song.downloads}</div>
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className="song-container-options" onClick={() => props.toggleOptionsOn(song, "single")}>
                    <p className="song-container-options-dots song-container-mobile-options-dots">...</p>
                </div>
            </div>
        </React.Fragment>
    ) 
}
