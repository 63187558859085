import React from 'react'
import { Switch, Route } from "react-router-dom"

import {Home} from './../home/home'
import {Popular} from './../popular/popular'
import {Latest} from './../latest/latest'
import {Artist} from './../artist/artist'
import {Album} from './../album/album'
import {Single} from './../album/single'
import {Playlist} from './../album/playlist'
import {UserPlaylist} from './../album/userplaylist'
import {Search} from './../search/search'
import {Register} from './../authentication/register'
import {SignIn} from './../authentication/signin'
import {MobileAuth} from './../authentication/mobileAuth'
import {Library} from './../library/library'
import { SinglesPage } from './../artist/singlesPage'

export const AppRoutes = ({ props }) => {
  return (
    <>
      <Switch>
        <Route exact path="/" render={() => <Home props={props}/>}/>
        <Route exact path="/popular" render={() => <Popular props={props}/>}/>
        <Route exact path="/m/auth" render={() => <MobileAuth props={props}/>}/>
        <Route exact path="/latest/:tab" render={() => <Latest props={props}/>}/>
        <Route exact path="/signin" render={() => <SignIn props={props}/>}/>
        <Route exact path="/register" render={() => <Register props={props}/>}/>
        <Route exact path="/artist/:id" render={() => <Artist props={props}/>}/>
        <Route exact path="/artist/:id/singles" render={() => <SinglesPage props={props}/>}/>
        <Route exact path="/album/:id" render={() => <Album props={props}/>}/>
        <Route exact path="/single/:id" render={() => <Single props={props}/>}/>
        <Route exact path="/playlist/:id" render={() => <Playlist props={props}/>}/>
        <Route exact path="/u/playlist/:id" render={() => <UserPlaylist props={props}/>}/>
        <Route exact path="/search/:query?" render={() => <Search props={props}/>}/>
        {/* Podcast Route */}
        <Route exact path="/podcast/:id" render={() => <Playlist props={props}/>}/> 
        <Route exact path="/library" render={() => {
            if(props.user.id) return <Library props={props}/>
            else return <SignIn props={props}/>
          }
        }/>
      </Switch>    
    </>
  )
}
