import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { Library } from "./../library/library"
import { MobileNav } from "./mobile-nav"
import $ from "jquery"
import logo from "./../../assets/logo.png"
import menu from "./../../assets/menu.png"
import close from "./../../assets/close.png"


export const Nav = ({ props }) => {
    const [ State, setState ] = useState({ authwidget:false, searchval:"", navbtn:{ home:false, popular:false, latest:false, current:"home" }})
    const searchbar = useRef()
    let path = useHistory().location.pathname.replace("/", "")

    try {
        if (props.search.query && searchbar.current.value === ""){
            searchbar.current.value = props.search.query
        }
    } catch (e){

    }
    
    // Nav Click Set State:
    const navbtn = (e) => {
        setState({ ...State, navbtn:{ ...State.navbtn,  [State.navbtn.current]:false, [e]:true, current:e }})
    }
    
    // On Reload:
    useEffect(() => {
        let page = (path === false || path  ===  "/") ?"home":(path.includes("latest")) ?"latest":path
        setState({ ...State, navbtn:{ ...State.navbtn,  [State.navbtn.current]:false, [page]:true, current:page }})
    }, [path])

    // Close Style:
    const style = {
        closeOff:{ display:"none" },
        closeOn:{ display:"" }
    }

    // Set Query State:
    const setquery = () => {
        setState({ ...State, searchval: $(".search_bar").val() })
    }

    const libraryAuth = () => {
        setState({
            ...State, authwidget: !State.authwidget
        })
    }

    const LibCheck = () => {
        if (props.user.id){
            props.setLibState()
        } else {
            libraryAuth()
        }
    }

    return (
        <div className='nav'>
            <div className="nav-main topGutter">
                <Link className="logo" to={"/"} onClick={() => navbtn("home")}>
                    <img src={logo} className="logo_item_" alt="" /></Link>
                <div className="search_bar_cont">
                    <Link className="cls_src_btn" to='/'>
                        <img
                            style={(useHistory().location.pathname.replace("/", "").includes("search")) ? style.closeOn : style.closeOff}
                            src={close} alt=""
                            className="cls_srch_btn" />
                    </Link>
                    <div className="searchdeskhold">
                        <input ref={searchbar} type="text" className="search_bar"  onKeyUp={() => setquery()} placeholder="Search song, artist / album"/>
                        <div className="svg_i">
                            <Link to={"/search/"+State.searchval}><button className="searchtextbtn">Search</button></Link>
                        </div>
                    </div>
                </div>
                <div className="navigation_">
                    <Link className={`nav_btn home_btn_nav ${(State.navbtn["home"]) ? 
                    "active_btnnav":""}`} onClick={() => navbtn("home")} to="/">Home</Link> 
                    <Link className={`nav_btn popular_btn_nav latesttab ${(State.navbtn["latest"]) ? "active_btnnav":""}`} onClick={() => navbtn("latest")} to="/latest/singles">Latest Songs</Link>
                    {  
                            (!props.user.id) ? 
                            <div style={(State.authwidget) ? { height: "" } : { height:0 }} className="alrt_cont_sgnin">
                                <div className="messge_mscapp">You need to sign-in to be able to view your library.</div>
                                <div className="btn_mscapp">
                                    <Link to='/signin'>
                                        <button className="sign_btn_mscapp">Sign In</button>
                                    </Link>
                                    <Link to='/register'>
                                        <button className="create_acca_btn_mscapp">Create Account</button>
                                    </Link>
                                </div>
                            </div>
                            :null
                    }
                    {
                        (props.user.id) ? <Library props={props}/> : null
                    }
                </div>
                <div className="library_cont" onClick={() => LibCheck()}>
                    <img src={menu} className="lib_icon" alt=""/>
                    <span className="lib_text">Library</span>
                </div>
            </div>

            <MobileNav props={props}/>
        </div>
    )
}