import React from "react"
import { Link } from "react-router-dom"

export const MobileAuth = () => {
    return (
        <div className="prompt_container">
            <div className="_prompt_">
                <h1 className="reg_user_hdr_land1_">Sign In</h1>
                <div className="promptmessage">Log in to your account to use your library & songs.</div> 
                <div className="prompt_btn_cont">
                    <Link to="/signin"><button className="signin_btn_prompt">Sign In</button></Link>
                    <Link to="/register"><button className="register_btn_prompt">Register Account</button></Link>
                </div>
            </div>
        </div>
    )
}