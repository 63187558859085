import React from "react"
import { Link } from "react-router-dom"
import { landingPagePlaylistStyles } from "../constants/constants"

export const LandingPagePlaylists = ({ props }) => {
  let playlists = Object.values(props.playlists)
  let secondpl = playlists.splice(0, 2)
  playlists =  [ ...playlists, ...secondpl ]
  
  return (
      <div>
          {
              Object.values(playlists).map((a, i) => (a.playlistName) && (
                  <Link key={i} style={{ cursor: "pointer" }} to={`/playlist/${a.playlistName}`}>
                      <div className="landing-page-playlist-item">
                          <div className="plitemhm">
                              <img className="plitemhmimg" src={a.artwork} alt=""/>
                          </div>
                          <div style={landingPagePlaylistStyles}>{a.playlistName}</div>
                      </div>
                  </Link>
              ))
          }
      </div>
  )
}