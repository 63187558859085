import React, { useEffect } from "react"
import { useAdSequences } from "../latest/hooks"

export const RightAdTemplate = ({ ad, props }) => {
  const { showAd, closeAd } = useAdSequences()
  const { image, title, descrition, buttonLink, buttonType } = ad.creative
  const { client } = ad.details

  useEffect(() => {
      showAd()
      return closeAd
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ads.reg])

  return (
      <div className="largeadlatest">
          <div className="ad-container-lazy-load">
              <div className="ad-container-lazy-load-meta-one">
                  <div className="ad-logo">AD</div>
                  <div className="ad-container-lazy-load-meta-text">{client}</div>
              </div>
              <div className="adimagecontainer_lazy_latest">
                  <img className="adtimg" src={image} alt=""/>
              </div>
              <div className="fsa_adtextcontainer_lazy_latest">
                  <div className="adtextcontent_lazy_latest">
                      <div className="adtextheader_lazy_latest">{title}</div>
                      <div className="adtextdescription_lazy_latest">{descrition}</div>
                  </div>
                  <a className="adbtnlink_lazy_latest" 
                      href={buttonLink} 
                      target="blank">
                      <div className="adcalltoaction_lazy_latest">{buttonType}</div>
                  </a>
              </div>
          </div>
      </div>
  )
}
