import { AlbumsResults } from "../search/results/albumResults"
import { ArtistResults } from "../search/results/artistResults"
import { SearchSongResults } from "../search/results/searchSongResults"

export const rightAdStyles = { end:{
  position:"relative", 
  right:"0"
}}

export const homeSinglesLazyLoadStyles = {
  container: { 
      play: { background: "rgb(148, 0, 34)" }, 
      off: { background: "" },
  },
  options: {
      play: { background: "rgb(148, 0, 34)", color: "#e49a9d" },
      off: { background:"" },
  }
}

export const landingPagePlaylistStyles = {
  color:"#d8d8d8", 
  textAlign:"center", 
  fontWeight:500, 
  fontSize:"10px",  
  height: "15px", 
  margin:"10px auto"
}

export const SongContainerStyles = {
  container: { 
      play: { background: "rgb(148, 0, 34)" }, 
      off: { background:"", transform: "" },
  },
  options: {
      play: { background: "rgb(148, 0, 34)", color: "#e49a9d" },
      off: { background:"" },
  }
}

export const BACKEND_URLS = {
  songs: (time, qty) => `https://data.strma.app/songs/${time}/${qty}`,
  albums: (time, qty) => `https://data.strma.app/albums/${time}/${qty}`,
  playlists: "https://data.strma.app/h/playlists",
  popular: "https://data.strma.app/popular",
  search: query => `https://data.strma.app/search/${query}`,
  getAlbum: id => `https://data.strma.app/album/${id}`,
  getSong: id => `https://data.strma.app/song/${id}`,
  getArtist: id => `https://data.strma.app/artist/${id}`,
  getPlaylist: id => `https://data.strma.app/playlists/${id}`,
  getPodcast: id => `https://data.strma.app/podcast/${id}`,

  // Update M:
  updateAdMetric: (id, type) => `https://data.strma.app/ad/${id}/${type}`,
  ads: () => "https://data.strma.app/sda",
  updateSongMetric: (type, id) => `https://data.strma.app/m/${id}/${type}`,

  // Auth:
  signin: () => "https://data.strma.app/u/s",
  register: () => "https://data.strma.app/u/r",

  // PL Ops:
  delFromPl: (uid, playlistid, songid) => `https://data.strma.app/p/sd/${uid}/${playlistid}/${songid}`,
  addToPl: (uid, playlistid, songid) => `https://data.strma.app/p/sa/${uid}/${playlistid}/${songid}`,
  deletePlaylist: (playlistid, uid) => `https://data.strma.app/p/d/${uid}/${playlistid}`,
  createPl: (uid, plname) => `https://data.strma.app/p/c/${uid}/${plname}`,
  searchByArtistnameAndTitle: ({ artist, songTitle }) => `https://data.strma.app/songByArtistAndTitle/${artist}/${songTitle}`,
}

export const mobileNavDefaultState = {
  menu:false,
  authwidget:false,
  searchval:"",
  navbtn: {
      home:false, 
      search:false, 
      latest:false, 
      library:false, 
      current:"home"
  }
}

export const searchSongStyles = {
  container:{ 
      play: { background: "rgb(148, 0, 34)" }, 
      off: { background:"" },
  },
  options:{
      play: { background: "rgb(148, 0, 34)" },
      off: { background:"" },
  }
}
 export const metaTagContent = {
   search: "Search your favourite Zimbabwean songs only on Strma.",
 }

 export const searchIconStyles = {
  cs1:{
      fill: "none", 
      stroke: "#fff", 
      strokeMiterlimit: 10, 
      strokeWidth: "5px"
  },
  cs2: {
      fill: 
      "#fff"
  }
}

export const seerchPageResultSections = [
  [ "songs", SearchSongResults ],
  [ "albums", AlbumsResults ],
  [ "artists", ArtistResults ]
]

export const initialState = ({ analytics, mixpanel }) => ({
  analytics,
  mixpanel,
  data:{},
  popular:null,
  queueState:false,
  library:false,
  createPlaylistWidget:false,
  raw:[],
  ads:{
      status:false,
      showready:false,
      right:null,
      reg:null,
      video:null,
      small:null
  },
  play:{
      state: null,
      ready:false,
      song:{
          id:null
      },
      paused:true
  },
  mobileQueue:false,
  queue:{
      state:false,
      music:[]
  },
  addToPlaylist:{
      songid:null,
      state:false
  },
  share:{
      state:false,
      id:null
  },
  homeData:{
      playlists:null, songs:null, albums:null
  },
  latest:{
      albums:null, singles:null
  },
  search:{
      data:{
          songs:null,
          artists:null,
          albums:null
      },
      query:null
  },
  user:{
      playlists:null,
      id:null,
      email:null,
  },
  options:{
      state:false,
      id:null,
      type:null,
  },
  ploptions:{
      state:false,
      id:null
  },
  createplaylist:{
      state:false,
      name:null
  }
})

export const musicPlayerInitState = { paused: true, active: false }

export const headerChevronStyles = {
    height: "10px",
    fill: "#a4a4a4",
    position: "relative",
    top: "0px",
    left: "4px"
}

export const mobileQueueInitState = {
    paused: true, active: false, song: null
}