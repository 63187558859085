import React from "react"

export const Like = ({ isLiked }) => {
	return (
		<React.Fragment>
			<svg className="Likeicn" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 512 512" style={{ enableBackground:"new 0 0 512 512" }} xmlSpace="preserve">
				<path className={`Likeicnpath ${(isLiked) ? "icnpathLiked":""}`}  d="M376.951,28.507h-0.899c-66.027,0-102.942,48.32-120.049,81.033
					c-17.408-32.714-54.022-81.033-120.049-81.033h-1.199C63.324,29.108,3.792,90.032,0.191,167.164
					c-4.503,94.538,70.937,158.164,183.782,254.204c19.509,16.807,40.218,34.515,62.126,53.423l9.905,8.703l9.905-8.703
					c21.608-18.908,42.617-36.616,62.126-53.423c112.844-96.04,188.278-159.666,183.775-254.204
					C508.206,90.031,448.381,29.107,376.951,28.507z"/>
				<path  className={`Likeicnpath ${(isLiked) ? "icnpathLiked":""}`}  d="M511.808,167.164c4.503,94.538-70.93,158.164-183.775,254.204
					c-19.509,16.807-40.518,34.515-62.126,53.423l-9.905,8.703V109.54c17.107-32.714,54.022-81.033,120.049-81.033h0.899
					C448.381,29.107,508.206,90.031,511.808,167.164z"/>
				<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
			</svg>
		</React.Fragment>
	)
}

export const LikeSong = ({ isLiked }) => { 
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`LikeSongIconBtm ${(isLiked) ? "icnpathLiked":""}`} > 
			<path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 
			12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 
			L12,21 Z"/> 
		</svg>
	)
}
export const BigLike = () => {
	return (
		<React.Fragment>
			<svg className="Likeicn biglike" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 512 512" style={{ enableBackground:"new 0 0 512 512" }} xmlSpace="preserve">
				<path className="Likeicnpath"  d="M376.951,28.507h-0.899c-66.027,0-102.942,48.32-120.049,81.033
					c-17.408-32.714-54.022-81.033-120.049-81.033h-1.199C63.324,29.108,3.792,90.032,0.191,167.164
					c-4.503,94.538,70.937,158.164,183.782,254.204c19.509,16.807,40.218,34.515,62.126,53.423l9.905,8.703l9.905-8.703
					c21.608-18.908,42.617-36.616,62.126-53.423c112.844-96.04,188.278-159.666,183.775-254.204
					C508.206,90.031,448.381,29.107,376.951,28.507z"/>
				<path  className="Likeicnpath"  d="M511.808,167.164c4.503,94.538-70.93,158.164-183.775,254.204
					c-19.509,16.807-40.518,34.515-62.126,53.423l-9.905,8.703V109.54c17.107-32.714,54.022-81.033,120.049-81.033h0.899
					C448.381,29.107,508.206,90.031,511.808,167.164z"/>
				<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
			</svg>
		</React.Fragment>
	)
}

