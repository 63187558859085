import React from 'react'
import {useLocation} from 'react-router-dom'

export const Options = ({ props }) => {
    const id = props.options.id
    const type = useLocation().pathname

    const openPlaylistsWidget = () => {  
        if(props.user.playlists){ 
            props.openPlaylistsWidget(id)
        }else{
            alert('Please sign in to access your playlists!')
        }
    }

    const deletesong = () => {
        const plid = type.replace('/u/playlist/', '')
        const data = {plid, uid: props.user.id, songid: id}
        props.deleteFromPlaylist(data)
    }

    const play = () => props.play({id})

    return (
        <div className="contextoverly">
            <div className="contextoverlyhold">
                <div className="context_menu">
                    <div className="cxthold" onClick={play}>
                        <span className="cxt_menu_item">
                            <span className="ctxclosetxtpl">Play</span>
                        </span>
                    </div>
                    <div className="cxthold" onClick={openPlaylistsWidget}><span className="cxt_menu_item">Add to Playlist</span></div>
                    <div className="cxthold" onClick={() => props.addToQueue(id)}> <span className="cxt_menu_item">Add to Queue</span></div>
                    <div className="cxthold" onClick={() => props.toggleShare(id, 'single')}> <span className="cxt_menu_item">Share Now</span></div>
                    {
                        type.includes('u/playlist') && <div className="cxthold" onClick={deletesong}>
                            <span className="cxt_menu_item">Delete</span>
                        </div>
                    }
                    <div className="closectxhold" onClick={() => props.toggleOptionsOff(null)}>
                        <span className="ctxclosetxt">Close</span>
                    </div>
                </div>
            </div>
        </div>
    )
 }