import React from 'react'

export const LazyLoadLatest = () => {
  return (
    <>
      {[1,2,3,4,5,6,7,8,9,10,11,12].map((a, k) => (
          <div key={k} className="latest-music-song-item latest-music-lazy-load">
              <div className="latest-music-songartwork">
                  <div className="latest-music-songartwork-img_lazy"/>
              </div>
              <div className="latest-music-song-meta">
                  <div className="track-song-name song-play-name_lazy"/>
                  <div className="latest-music-song-artist song-play-artist_lazy"/>
              </div> 
          </div> 
      ))}
  </>
  )
}
