export const HOME = "HOME"
export const QUEUE = "QUEUE"
export const MQUEUE = "MQUEUE"
export const SEARCH = "SEARCH"
export const LIBRARY = "LIBRARY"
export const TABS = "TABS"
export const ALBUM = "ALBUM"
export const ARTIST = "ARTIST"
export const SINGLE = "SINGLE"
export const PLAYLIST = "PLAYLIST"
export const CLEAN = "CLEAN"
export const LATEST = "LATEST"
export const POPULAR = "POPULAR"
export const SONGSH = "SONGSH"
export const SONGSL = "SONGSL"
export const ALBUMS = "ALBUMS"
export const RAWUPDATE = "RAWUPDATE"
export const OPTIONS_OFF = "OPTIONS_OFF"
export const OPTIONS_ON = "OPTIONS_ON"
export const TOGGLEPLAYLIST = "TOGGLEPLAYLIST"
export const SHARE = "SHARE"
export const AD = "AD"
export const ADS = "ADS"
export const AD_OFF = "AD_OFF"
export const ADSHOWREADY = "ADSHOWREADY"
export const FLIPADS = "FLIPADS"
export const PREV = "PREV"
export const NEXT = "NEXT"
export const PLAY = "PLAY"
export const READY = "READY"
export const PAUSE = "PAUSE"
export const DOWNLOAD = "DOWNLOAD"
export const PLAYPAUSE = "PLAYPAUSE"
export const AUTHENTICATION = "AUTHENTICATION"
export const RE_AUTHENTICATION = "RE_AUTHENTICATION"
export const PUSHTOQUEUE = "PUSHTOQUEUE"
export const ADDTOQUEUE = "ADDTOQUEUE"
export const ADDINGDONE = "ADDINGDONE"
export const TOGGLECREATEPL = "TOGGLECREATEPL"
export const CREATEPL = "CREATEPL"
export const DELETEPL = "DELETEPL"
export const DELETEFROMPL = "DELETEFROMPL"
export const OPENPLAYLISTSWIDGET = "OPENPLAYLISTSWIDGET"
export const CLOSE_QUEUE_STATE = "CLOSE_QUEUE_STATE"
export const OPEN_QUEUE_STATE = "OPEN_QUEUE_STATE"
