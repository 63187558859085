import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { playSongHandler } from "../helper"
import { LikeSong } from "../../library/like"
import play from "../../../assets/play.png"
import pause from "../../../assets/pause.png"
import toggle from "../../../assets/up.png"
import $ from "jquery"

const BottomContainer = ({ props }) => {
    let audio, duration, time, slider, dot, timer
    const [ State, setState ] = useState( { paused:true, showSpinner:false, active:false, deleteDone:false, addDone:false } )
    const H = useHistory()

    useEffect(() => { 
        // Set Elements:
        audio = $(".aud")[0]
        duration = $(".player-duration-time")[0]
        time = $(".player-current-time")[0]
        slider = $(".player-duration-slider-dot")[0]
        dot = $(".player-slider-dot")[0]
        // On Play/Pause icon Click/New Track Handler:
        playSongHandler( duration, time, slider, audio, timer, State, setState, props, dot)
        if (props.PLAY.state){  setState({ ...State, paused:false }) }
        else { setState({ ...State, paused:true }) }
    }, [props.PLAY]) 

    const OpenQueue = function(){
        H.push({ search:"?q=true" })
        props.openQueueState()
    }

    useEffect(() => {
        if (props.addedToPlaylist === sessionStorage.getItem("songToAddToPlaylistID")){
            setState({ ...State, addDone:true, showSpinner:true })
            setTimeout(() => setState({ ...State, showSpinner:false }), 900)
            setTimeout(() => setState({ ...State, addDone:false }), 3000)
        }
    }, [props.addedToPlaylist])
    
    useEffect(() => {
        if (props.deleteDone === sessionStorage.getItem("songToAddToPlaylistID")){
            setState({ ...State, deleteDone:true })
            setTimeout(() => setState({ ...State, deleteDone:false }), 3000)
        }
    }, [props.deleteDone])

     
    return (
        <React.Fragment>
            {/* Playlist Add/Remove Notification */}
            <React.Fragment>
                <div 
                    style={(State.deleteDone) ?{ color:"black", background:"white" }:{}} 
                    className={`addRemoveNotification ${(State.deleteDone||State.addDone) ? "showAddRemoveNotification":""}`}
               >
                    <div className="addRemoveNotificationInn">
                        {
                            (State.deleteDone) ? "Song Removed from Playlist":(State.addDone) ? "Song Added to Library":null
                        }
                    </div>
                </div>
            </React.Fragment>
 
            {/* Bottom Bar */}
            <React.Fragment>
                <div className="bottomGutter_mobile">
                    <div className="music_controls">
                        {/* Toggle Arrow */}
                        <div className="togglePlayer" onClick={ () => OpenQueue() }> 
                            <img style={(props.mobileQueue) ? { transform: "rotate(180deg)" } : { transform: "" }}
                                src={toggle} className="toggleIconplayer" alt=""/>
                        </div>

                        {/* Song Meta Details */}
                        <div className="metaText" onClick={ () => OpenQueue() }>
                            <div className="songName_meta">{(props.queue.music[0]) ? props.queue.music[0].songTitle : "No Song Selected"}</div>
                            <div className="artistName_meta">{(props.queue.music[0]) ? props.queue.music[0].artist : "No Artist"}</div>
                        </div>

                        {/* Like Button */}
                        <div className="songLikeButton" onClick={() => {
                            if (props.user.id){
                                sessionStorage.setItem("songToAddToPlaylistID", props.queue.music[0]["id"])
                                props[(props.queue.music[0].isLiked) ? "deleteFromPlaylist":"addSongToPlaylist"]({
                                    uid:"-L8miINTXrWjXmWeS2Fk", songid:props.queue.music[0]["id"], plid:"MySongs" 
                                })
                            }
                            else H.push("/m/auth?redirect=account")
                        }}>
                            <div className="songLikeButtonInn">
                                {
                                    (props.queue.music[0]) ?
                                    <LikeSong isLiked={(props.queue.music[0]["isLiked"]) ? true : false}/>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        {/* Play Pause button */}
                        <div className="media-controls media-controlsm">
                            <img src={play} style={(props.PLAY.paused) ? { display:"" } : { display:"none" } }  className="play playpause playpausem" onClick={() => {
                                if (props.PLAY.state){ props.playpause() }
                            }} title="play" alt=""/>
                            <img src={pause} style={(props.PLAY.paused) ? { display:"none" } : { display:"" }}  className="pause playpause playpausem" onClick={() => {
                                if (props.PLAY.state){ props.playpause() }
                            }} title="pause" alt=""/>
                        </div>
                    </div>
                    {/* <div className="duration_barcontainer">
                        <div className="player-duration-bar"  onClick={() => {}}>
                            <div className="player-duration-slider-dot"></div>
                        </div>
                    </div> */}
                    <audio className="aud"></audio>
                </div>
            </React.Fragment>

            {/* Swirl Spinner */}
            <React.Fragment>
                <div className="likeSpinnerHold">
                    {
                        (State.showSpinner) ?
                            <div className="spin-wrapper">
                                <div className="spinner"></div>
                            </div>
                            :
                            null
                    }
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}



export const BottomM = ({ props }) => { 

    return (
        <div>
            <BottomContainer props={props} />
        </div>    
    )
}