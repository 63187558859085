import React, { useState, useEffect } from "react"
import { changeMobileQueueRgb, playSongHandler } from "../helper"
import { QueueArtworkSection } from "./queueArtwork"
import { MobileMediaControls } from "./mobileMediaControls"
import { mobileQueueInitState } from "../../constants/constants"
import { MobileQueueNowPlayingSection } from "./MobileQueueNowPlayingSection"
import { MobileQueueMetaSection } from "./MobileQueueMetaSection"

export const MobileQueue = ({ props, audio, slider, time, timer, duration, currentTime }) => {
    const [ State, setState ] = useState(mobileQueueInitState)
    const updateBurationBar = () => audio && (audio.currentTime = currentTime)
    
    useEffect(() => {
        setState({ ...State, song: props.PLAY.state ? props.queue.music[0] : null })
    }, [props.queue.music[0]])

    useEffect(() => {
        changeMobileQueueRgb(State.song)
    }, [State.song])

    useEffect(() => {
        const dot = document.querySelector(".player-slider-dot")[0]
        playSongHandler(duration, time, slider, audio, timer, State, setState, props, dot)
        setState({ ...State, paused: props.PLAY.state ? true : false })
    }, [props.PLAY])

    return (
        <div className="mobile-queue-toggle-container"
            style={{ top: props.queueState ? "0px" : "" }}>
            <MobileQueueNowPlayingSection props={props}/>
            <QueueArtworkSection props={props}/>
            <div className="mobile-queue-toggle-controls">
                <MobileQueueMetaSection props={props}/>
                <div className="duration_barcontainer_toggle">
                    <div className="player-duration-bar player-duration-bar-mobile" onClick={updateBurationBar}>
                        <div className="player-duration-slider-dot player-duration-slider-mobile"/>
                        <div className="player-slider-dot"/>
                    </div>
                    <div className="player-current-time-mobile">0:00</div>
                    <div className="player-duration-time duration_time_toggle">0:00</div>
                </div>
                <div className="media-controls media-controls-mobile">
                    <MobileMediaControls props={props}/>
                </div>
            </div>
        </div>
    )
}