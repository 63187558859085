import React from 'react'

export const HomePageLazyLoadedSingles = () => {
  const items = [...Array(12)]
  const homePageLazyLoadImgStyle = {
      height: '35px',
      width: '38px',
      borderRadius: '5px', 
      background: 'rgb(41, 38, 38)', 
      display: 'inline-block', 
      float: 'left'
  }
  return (
      <div>
          {items.map((a, k) => (
              <div key={k} style={{ width: '40vw' }} className="landing-page-single-item">
                  <div style={{ width: '90%' }} className="landing-page-single-meta">
                      <div style={homePageLazyLoadImgStyle}/>
                      <div className="landing-page-single-meta-text">
                          <div className="landing-page-single-meta-title_l"/>
                          <div className="landing-page-single-meta-artist_l"/>
                      </div>
                  </div>
              </div>
          ))}
      </div>
  )
}