import Analytics from "analytics"
import googleAnalytics from "@analytics/google-analytics"
// import mxpanel from "mixpanel-browser"

export const analytics = Analytics({
  app: "Strma Music",
  plugins: [
    googleAnalytics({
      trackingId: "G-W630JE58ES"
    })
  ]
})
// const mx = mxpanel.init("")
export const mixpanel = { track: () => { } }
