import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { PlaylistLayout } from "../layouts/collection/collection"

export const Single = ({ props }) => {
    const [{ single }, setState ] = useState({ single: null })
    const { id } = useParams()

    useEffect(() => {
        props.getSingle(id)
        props.analytics.page()
        props.analytics.track("single_view")
        props.mixpanel.track("page_view")
        return props.cleanData
    }, [])

    if (props.data.single && single === null) setState({ single: props.data.single })

    const singleMeta = single ? {
        id,
        title: single.songTitle,
        author: single.artist,
        artwork: single.image,
        tracks: { [id]: single }
    } : {}

    return (
        <PlaylistLayout 
            props={props}
            loading={!single}
            collection={singleMeta}
            type='single'
        />
    )
}


