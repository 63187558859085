import React, { useRef, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { DefaultMobileSearchPage } from "./defaultMobileSearchPage"
import { seerchPageResultSections } from "./../constants/constants"
import $ from "jquery"

export const SearchMobile = ({ props }) => {
    const [ State, setState ] = useState({ searchval: "" })
    const searchbar = useRef()
    const params = useParams()

    useEffect(() => {
        try {
            if (props.search.query && searchbar.current.value) searchbar.current.value = props.search.query
        } catch (e) {
            props.mixpanel.track("error", {
                type: "mobile_search",
                message: e.message
            })
        }
        return props.cleanData
    }, [])

    // Set Query State:
    const setquery = () => setState({
        ...State, 
        searchval: $(".searchmobile_mobile").val()
    })

    const resultSections = () => seerchPageResultSections.map(([ title, Component ], i) => (
        <div className="songrsultm" key={i}>
            <h1 className="resulthdrm">{title}</h1>
            <div className="rsltcontm songreshold">
                {props?.search?.data[title] && <Component props={props}/>}
            </div>
        </div>
    ))

    return (
        <div className="srch_page_mobile navpage">
            <div className="srch_inptcont">
                <div className="searchholderm">
                    <svg className="searchmenu_icn" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.96 90.06">
                        <defs><style/></defs>
                        <title>search</title>
                        <ellipse className="cls-1" cx="38.91" cy="38.22" rx="36.41" ry="35.72" style={{ fill:"none", stroke:"#fff", strokeMiterlimit:10, strokeWidth:"5px" }} ></ellipse>
                        <path className="cls-2" d="M577,992.45a27.87,27.87,0,0,0,7.12-6.75l17.25,15.78s-.49,6.71-6.69,7.52Z" transform="translate(-510.45 -918.94)" style={{ fill:"none", stroke:"#fff", strokeMiterlimit:10, strokeWidth:"5px" }}></path>
                    </svg>
                    <input 
                        className="searchmobile_mobile" 
                        placeholder="Song, artist or album..." 
                        ref={searchbar} 
                        type="text"  
                        onKeyUp={setquery} 
                    />
                    <Link 
                        to={`/search${!State.searchval ? "/"+State.searchval:(!params.query && !params.query) ? "/" + params.query : ""}`}
                    ><button className="searchtextbtn">Search</button></Link>
                </div>
            </div>            
            {props.search.query ? <div className="resultsmainhold">{resultSections()}</div>
                : DefaultMobileSearchPage()}
        </div>
    )
} 