import _ from "lodash"
import { rightAdStyles } from "../constants/constants"
import $ from "jquery"

export const useLatestMusic = ({ props, albums, singles, PATH }) => {
  const ShowMoreAlbums = () => {
    const lastAlbums = Object.values(albums)[0]
    props.getAlbums(lastAlbums["timeStamp"])
  }
  
  const ShowMoreSingles = () => {
    const lastSong = Object.values(singles)[0]
    props.getSongsL(lastSong["timeStamp"])
  }

  const showMore = PATH === "singles" ? ShowMoreSingles : ShowMoreAlbums
  const lastitem = singles ? Object.values(singles)[Object.values(singles).length -1 ]["timeStamp"] : null
  const musicToRender = () => (PATH === "singles" ? _.reverse(Object.values(singles || {}))
    : Object.values(albums || {}).sort((a, b) => (a.timeStamp - b.timeStamp)))

  return {
    showMore,
    lastitem,
    musicToRender
  }
}

export const useAdSequences = ({ props }) => {
  const showAd = () => {
      setTimeout(() => $(".adtimg").css({
          opacity:1, 
          right:rightAdStyles.end.right
      }), 2000)
      setTimeout(() => $(".fsa_adtextcontainer_lazy_latest").css({
          opacity:1, 
          right:rightAdStyles.end.right })
      , 2080)
  }
  const closeAd = () => {
      $(".adtimg").css({ opacity: "" })
      setTimeout(() => $(".fsa_adtextcontainer_lazy_latest").css({ opacity:"" }), 100)
      setTimeout(() => $(".fsa_adtextcontainer_lazy_latest, .adtimg").css({ right:"" }), 1000)
  }
  const flipAds = () => {
      let time = 0
      setInterval(() => {
      if (time>= 20) {
          clearInterval(flipAds)
          props.flipads()
      } else time = time + 1
    }, 1000)
  }

  return {
    showAd,
    closeAd,
    flipAds
  }
}
