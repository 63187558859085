import React from 'react'
import { useHistory } from 'react-router-dom'

export const BackArrow = ({ path }) => {
  const H = useHistory()
  const backHandler = path ? () => H.push(path) : H.goBack
  return (
      <div onClick={backHandler}>
        <button className="backArrow">
            <div className='arrow_svg_container'>
                <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby="chevronLeftIconTitle" 
                    stroke="white" strokeWidth="0.6" strokeLinecap="square" 
                    strokeLinejoin="miter" fill="none" color="white" 
                    className='arrow_svg'
               > 
                    <polyline points="14 18 8 12 14 6 14 6" /> 
                </svg>
            </div>
            <div className='arrow_back_text'>Back</div>
        </button>
      </div>
  )
}