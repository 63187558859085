import React from 'react'
import {Helmet} from 'react-helmet'
import { metaTagContent } from '../constants/constants'

export const GenerateMetaTags = (route) => (
  <Helmet>
      <meta class="metadescript" name="description" content={metaTagContent[route]}/>
      <meta class="metakeywords" name="keywords" content="Strma, zimbabwe, music, online, listen, streaming, play, digital, album, artist, playlist, strmaio, strma.app, web player, zim-music"/>
      <meta class="metaurl" property="og:url" content={`https://player.strma.app/${route}`}/>
      <meta class="metaogtitle" property="og:title"  content={metaTagContent[route]}/>
      <meta class="metaogimage" property="og:image" content="./../../assets/feature.png"/>
  </Helmet>
)
