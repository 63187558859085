import React from "react"
import prev from "./../../assets/prev.png"
import play from "./../../assets/play.png"
import pause from "./../../assets/pause.png"
import next from "./../../assets/next.png"
import $ from "jquery"
import RgbMaster from "rgbaster"

export const CHROME_META = (props) => {
    if ("mediaSession" in navigator) {
        const song = props.queue.music[0]

        navigator.mediaSession.metadata = new window.MediaMetadata({
            title: song["songTitle"],
            artist: song["artist"],
            album: "Strma Music",
            artwork: [
                {
                    src: song["image"],
                    sizes: "256x256", type: "image/png"
                },
                {
                    src: song["image"],
                    sizes: "512x512", type: "image/png"
                },
            ]
        })
        navigator.mediaSession.setActionHandler("play", () => {
            if (props.PLAY.state) props.playpause()
        })
        navigator.mediaSession.setActionHandler("pause", () => {
            if (props.PLAY.state) props.playpause()
        })
        navigator.mediaSession.setActionHandler("previoustrack", () => props.PREV())
        navigator.mediaSession.setActionHandler("nexttrack", () => props.NEXT())
    }
}

export const MobilePlayerTimer = (duration, audio, dot, timeFormat, time, slider, props, timer) => {
    if (duration && audio && dot && timeFormat && time && slider && props && timer) {
        if (duration.innerText === "NaN:NaN" || duration.innerHTML === "0.00") {
            const dur = timeFormat(audio.duration)
            duration.innerHTML = (dur === "NaN:NaN") ? "0.00" : timeFormat(audio.duration)
        }
        // Update Timer and Slider:
        time.innerHTML = timeFormat(audio.currentTime)
        // Get audio Element
        let width = Math.ceil((audio.currentTime / audio.duration) * 100) + "%"
        // Update duration Bar
        slider.style.width = width
        dot.style.left = width
        // Stop Updating Timer:
        if (audio.currentTime >= audio.duration) {
            if (props.queue.music.length > 1) {
                props.NEXT()
            } else {
                clearInterval(timer)
                time.innerHTML = "0.00"
                duration.innerHTML = "0.00"
                slider.style.width = "0%"
            }
        }
    }
}

export const playSongHandler = (duration, time, slider, audio, timer, State, setState, props, dot) => {

    const timeFormat = (raw) => {
        let ctime = (raw / 60)
        let minutes = Math.floor(ctime); if (minutes < 10) {minutes = "0" + minutes}
        let secr = ctime - minutes
        let sec = Math.ceil(secr * 60); if (sec < 10) {sec = "0" + sec}

        return minutes + ":" + sec
    }

    if (!props.PLAY.paused) {
        try {
            // Set Src:
            if (audio.current) {
                if (audio.current.src !== props.queue.music[0]["songURL"]) {
                    audio.current.src = props.queue.music[0]["songURL"]
                    props.mixpanel.track("song_play")
                }
            }
            else {
                if (audio.src !== props.queue.music[0]["songURL"]) {
                    audio.src = props.queue.music[0]["songURL"]
                }
            }

            // Play:
            (audio.current) ? audio.current.play() : audio.play()

            // Media Controls:
            CHROME_META(props, setState, State)

            // Update Meta details:    
            timer = setInterval(() => {
                if (duration.current && audio.current) {
                    if (duration.current.innerText === "NaN:NaN" || duration.current.innerHTML === "0.00") {
                        const dur = timeFormat(audio.current.duration)
                        duration.current.innerHTML = (dur === "NaN:NaN") ? "0.00" : timeFormat(audio.current.duration)
                    }
                    // Update Timer and Slider:
                    time.current.innerHTML = timeFormat(audio.current.currentTime)
                    // Get audio Element
                    let width = Math.ceil((audio.current.currentTime / audio.current.duration) * 100) + "%"
                    // Update duration Bar
                    slider.current.style.width = width
                    // Stop Updating Timer:
                    if (audio.current.currentTime >= audio.current.duration) {
                        if (props.queue.music.length > 1) {
                            props.NEXT()
                        } else {
                            clearInterval(timer)
                            time.current.innerHTML = "0.00"
                            duration.current.innerHTML = "0.00"
                            slider.current.style.width = "0%"
                        }
                    }
                } else {
                    MobilePlayerTimer(duration, audio, dot, timeFormat, time, slider, props, timer)
                }
            }, 100)

        }
        catch (err) {
            props.mixpanel.track("error", {
                type: "songplay",
                message: err.message
            })
        }
    } else {
        try {
            audio.current.pause()
        } catch (e) { console.clear()}
    }
}

export const useMusicPlayer = ({ props, audioPlayerRefElements }) => {
    let [ time, slider, duration, audio, durationhold ] = audioPlayerRefElements
    let timer, currentTime
    const changePlayState = ({ State, setState }) => {
        if (props.PLAY.state) setState({ ...State, paused: false })
        else setState({ ...State, paused: true })
    }
    const PlayPauseHandler = () => props.PLAY.state && props.playpause()
    const PlayPauseButton = ({ title }) => {
        const icon = { play: play, pause: pause }
        const displaySetting = {
            pause: props.PLAY.paused ? "none" : "",
            play: props.PLAY.paused ? "" : "none"
        }
        return (
            <img
                title={title}
                src={icon[title]}
                className={`${title} playpause`} 
                onClick={PlayPauseHandler} 
                style={{
                    display: displaySetting[title]
                }}
                alt="PlayPauseButton"
            />
        )
    }
    const IteratorButton = ({ title }) => (
        <img src={(() => ({ next, prev }))()[title]} 
            className={`${title} iterators_`} 
            title={title}
            alt="IteratorButton"
        />
    )
    const switchToPreviousSong = () => props.queue.music.length > 0 && props.PREV()
    const switchToNextSong = () => props.queue.music.length > 0 && props.NEXT()
    const durationBar = (event) => {
        try {
            audio = document.querySelector("audio")
            duration = document.querySelector(".player-duration-time")
            time = document.querySelector(".player-current-time")
            slider = document.querySelector(".player-duration-slider-dot") 
            let durationbar_width = $(".player-duration-bar").css("width").replace("px", "")
            let y = event.nativeEvent.offsetX
            let z = (y / durationbar_width)
            currentTime = z * audio.duration
            audio.currentTime = currentTime
        }
        catch (e) {
            props.mixpanel.track("error", {
                type: "bottom_player_desktop",
                message: e.message
            })
        }
    }
    const SongIsPlaying = props.queue?.music[0] ? true : false
    const SongTitle = SongIsPlaying ? props.queue.music[0].songTitle : ""
    const ArtistName = SongIsPlaying ?  props.queue.music[0].songTitle : ""
    const SongMetaClassName = SongIsPlaying ? "" : "no_song_playing"
    return {
        PlayPauseHandler,
        PlayPauseButton,
        IteratorButton,
        switchToPreviousSong,
        switchToNextSong,
        changePlayState,
        durationBar,
        SongTitle,
        ArtistName,
        SongMetaClassName,
        time, slider, duration, audio, durationhold, timer, currentTime
    }
}

export const buttonEffect = (class_) => {
    const button = document.querySelector(`${class_}`)
    button.classList.add("mediaButtonClicked")
    setTimeout(() => button.classList.remove("mediaButtonClicked"), 300)
}

export const songIteratorHandler = (buttonClass, type, props) => {
    buttonEffect(buttonClass)
    if (props.queue.music.length > 0) switch (type) {
        case "prev": return props.PREV()
        case "next": return props.PREV()
        default: return 
    }
}

export const changeMobileQueueRgb = (song) => {
    song && setTimeout(async () => {
        const result = await RgbMaster(song.image, { scale: 0.4 })
        const dominant = result[0].color
        $(".fade-player")
            .css("background", `linear-gradient(178deg, ${dominant} -130%, #1d1b1b 80%)`)
    }, 1000)
}

export const ToggleOptionsHandler = (history, props) => {
    if (props.queue.music[0]) {
        history.goBack()
        setTimeout(() => props.toggleOptionsOn(
            props.queue.music[0],
            "single",
            props.queue.music[0]["image"]
        ), 0)
    }
}

// eslint-disable-next-line no-unused-vars
export const HandleGetArtistID = async (artist_name, history) => {
    // const req = await axios.get(BACKEND_URLS.getArtistID(artist_name)).then(response => response.data)
    // H.push(`/artist/${req.artistID}`)
}

export const mobileQueueSongNameStyles = {
    width: "unset",
    fontSize: "15px",
    marginBottom: "2px",
    fontWeight: "600"
}
