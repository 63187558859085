import React from "react"

export const DownChevronIcon = () => (
  <span className="close_toggle_opn">
      <svg className="close_toggle_icon" xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
        x="0px" y="0px" width="512px" height="512px" viewBox="0 0 306 306"
        style={{ enableBackground: "new 0 0 306 306" }} xmlSpace="preserve">
        <g><g><g id="expand-more">
          <polygon points="270.3,58.65 153,175.95 35.7,58.65 0,94.35 153,247.35 306,94.35"
            data-original="#000000" className="active-path"
            data-old_color="#9F9E9E" fill="#A1A1A1"/></g></g></g>
      </svg>
  </span>
)