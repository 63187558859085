import React from "react"
import { LandingPagePlaylists } from "./LandingPagePlaylists"
import { LazyLoadedLandingPagePlaylists } from "../LazyLoaded/LazyLoadedLandingPagePlaylists"

export const Playlists = ({ props }) => {
    const renderPlaylist = () => props.playlists ? <LandingPagePlaylists props={props}/> : <LazyLoadedLandingPagePlaylists/>
    return (
        <div className="landing-page-playlists">
            <div className="landing-page-section-header">Discover</div>
            <div className="strma-playlists landing-page-playlists-section">
                <div className="landing-page-playlists-body">{renderPlaylist()}</div>
            </div>
        </div>
    )
}