import React, { useState, useEffect } from "react"
import { useHistory, useParams, Link } from "react-router-dom"
import { SongContainer } from "./../album/songMarkup"
import { Helmet } from "react-helmet"

import _ from "lodash"
import { BackArrow } from "../helpers/backArrow"

const styles = {
    container:{ 
        play: { background: "rgb(148, 0, 34)" }, 
        off: { background:"" },
    },
    options:{
        play: { background: "rgb(148, 0, 34)", border:"none",  boxShadow: "0px 0px 6px 2px #3a0b0b", color: "#e49a9d" },
        off: { background:"", border:"", boxShadow:"" },
    }
}
const artistTemplate = {
    artist: { artist: {}}, 
    songs:null, 
    albums:null
}

const Page = ({
    artist=artistTemplate, 
    props=null,
    id=null
}) => {
    const H = useHistory()
    const ARTIST = artist.artist.artist
    const SONGSp = artist.artist.songs ? [...artist.artist.songs.popular] : artist.artist.songs
    const SONGSn = artist.artist.songs ? [...artist.artist.songs.new] : artist.artist.songs
    const ALBUMS = artist.artist.songs ? { ...artist.artist.songs.artist } : artist.artist.albums
    const newSong = artist.artist.songs ? SONGSn[0] : null

    const artistArtwork = () => (ARTIST.profileImageUrl ? <img 
        className="playlist-artwork-container-img" 
        src={`${ARTIST.profileImageUrl}`}
        alt="artistArtwork"
    /> : <div className='playlist-artwork-container-img-lazy' />
    )

    const artistPopularSongs = () => (artist.artist.songs ? 
        SONGSp.splice(0, 5).map((a, i) => (<SongContainer 
            key={i} 
            song={a} 
            object='' 
            props={props}
        />
        )):<div>Songs Here...</div>
    )

    return (
        <div className="playlist-container playlist-containertag closedPage objectpgview"> 
            <div className="playlist-meta-container">
                <button className="playlist-container-close-page" onClick={H.goBack}>Close</button>
                <BackArrow />
                <div className="playlist-artwork-container playlist-artwork-container-second-class">
                    {artistArtwork()}
                </div>
                <div className="playlist-container-title">{ARTIST.artistName}</div>
                <div className="shareobjectbutton" onClick={() => props.toggleShare(id, "artist")}>Share Artist</div>
            </div>
            <div className="artist_library">
                <div className="artist_popular_music">
                    <h1 className="popular_artist_header_">Popular</h1>
                    <div className="popular_songs_cont">{artistPopularSongs()}</div>
                </div>

                <div className="latest_rls_artist_music_">
                    <h1 className="popular_artist_header_">Latest Release</h1>
                    <div>
                        {
                            newSong &&
                                <article className="new_release_song ns" id="song" 
                                    onClick={() => props.play(newSong)} 
                                    style={props.PLAY.song.id === newSong.id ? styles.container.play : styles.container.off}
                               >
                                    <img className="nw_rls_img" src={newSong.image}  alt="nw_rls_img"/>
                                    <section className="nw_rls_meta">
                                        <span className="title_nw_rels song-play-name">{newSong.songTitle}</span>
                                        <span className="date_nw_rels">{new Date(newSong.timeStamp).toDateString()}</span>
                                    </section>
                                </article>
                        }
                    </div>
                </div>
                
                <div className="artistallsingles">
                    <h1 className="popular_artist_header_">Singles</h1>
                    <div className="asthreeonly">
                        {
                            artist?.artist?.songs ? 
                            SONGSn.splice(0, 3).map((a, i) => (
                                <SongContainer key={i} 
                                    song={a} 
                                    object={""} 
                                    props={props} 
                                    type={"amsong"}
                                />
                            ))
                            :
                            <div>Songs Here...</div>
                        }
                    </div>
                    <div className="asmore">
                        <Link to={`/artist/${id}/singles`}>
                            <button className="asbtnall">See All</button>
                        </Link>
                    </div>
                </div>

                <div className="artist_albums_music">
                    <h1 className="popular_artist_header_">Albums</h1>
                    <div className="artist_albums_cont">
                        {ALBUMS && (
                            Object.values(ALBUMS).map((album, i) => (
                                <Link key={i} 
                                    className="album-search-item " 
                                    to={`/album/${_.findKey(ALBUMS, {
                                        Artist:album.Artist, 
                                        albumTitle: album.albumTitle
                                    })}`} 
                                    id="album"
                               >
                                    <img src={album.albumArtwork} className="album-search-item-img" alt="albumArtwork"/>
                                    <div className="album-search-item-meta">
                                        <div className="album-search-item-meta-title album">
                                            <div className="album-search-item-meta-title-text">{album.Artist}</div>
                                        </div>
                                        <div className="album-search-item-meta-artist">{ album.albumTitle} </div>
                                        <span className="al_it_artist album-search-item-meta-artistdb">{ album.albumTitle}</span>
                                    </div>
                                </Link> 
                            )))
                        }                         
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Artist = ({ props }) => {
    const [{ artist }] = useState({ artist: null })
    const { id } = useParams()

    useEffect(() => {
        props.getArtist(id)
        props.analytics.page()
        props.mixpanel.track("page_view")
        props.analytics.track("artist_view")
    }, [])

    return (
        <div>
            {
                artist && <Helmet>
                    <meta className="metadescript" name="description" content={`Enjoy their latest albums and tracks by ${props.artist?.artist?.artistName}`}/>
                    <meta className="metakeywords" name="keywords" content="Strma, zimbabwe, music, online, listen, streaming, play, digital, album, artist, playlist, strmaio, strma.app, web player, zim-music"/>
                    <meta className="metaurl" property="og:url" content={`https://player.strma.app/artist/${id}`}/>
                    <meta className="metaogtitle" property="og:title" content={`Listen to ${props.artist.artist.artistName}'s songs on Strma`}/>
                    <meta className="metaogdescription" property="og:description"content={`Enjoy their latest albums and tracks by ${props.artist.artist.artistName}`}/>
                    <meta className="metaogimage" property="og:image" content={props.artist.artist.profileImageUrl} />
                </Helmet>
            }
           {
               props?.data?.artist ? <Page 
               artist={props?.data} 
               id={id} 
               props={props} 
               /> : <Page  />
           }
        </div>
    )
}