import React from "react"
import { useHistory } from "react-router-dom"
import { HandleGetArtistID, mobileQueueSongNameStyles } from "../helper"

export const MobileQueueMetaSection = (props) => {
  const history = useHistory()
  const isThereSongInQueue = props?.queue?.music[0] ? true : false
  const songTitle = props?.queue?.music[0]?.songTitle || "No Song Selected"
  const artistClickHandler = () => HandleGetArtistID(props.queue.music[0].artist, history)

  return <div className="metaText metaText_toggle">
    <div className="metaholdertoggled">
        <div className="songName_meta songnametoggled"
            style={mobileQueueSongNameStyles}>{songTitle}</div>
          {isThereSongInQueue ? <div
            className="artistName_meta artistName_meta_toggle clickableArtistName"
            onClick={artistClickHandler}
            style={{ cursor: "pointer", fontSize: "12px" }}
          >{props?.queue?.music[0].artist}</div>
          :<div className="artistName_meta artistName_meta_toggle">No Artist</div>
        }</div>
  </div>
}