import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { PlaylistLayout } from "../layouts/collection/collection"

export const Album = ({ props }) => {
    const [{ album }, setState ] = useState({ album:null })
    const { id } = useParams()

    useEffect(() => {
        props.getAlbum(id)
        return props.cleanData
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const albumMeta = album ? {
        id,
        title: album.albumTitle,
        author: album.Artist,
        artwork: album.albumArtwork,
        tracks: album.Tracklist
    } : {}

    if (props.data.album && !album) {
        props.analytics.page()
        props.analytics.track("album_view")
        props.mixpanel.track("page_view")
        setState({ album: props.data.album })
    }
    
    return (
        <PlaylistLayout 
            props={props}
            loading={!album}
            collection={albumMeta}
            type='album'
        />
    )
}