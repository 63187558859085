import React from 'react'

export const TracklistLegend = ({ withDates }) => {
  const gridColumnAdjestment = () => ({
    display: 'grid',
    gridTemplateColumns: withDates ? '27% 13% 18% 15% 10%' : '30% 27% 15% 15%' 
  })

  return (
    <table className="album_legend_open">
      <tr style={gridColumnAdjestment()}>
        <td className={`albm_lgnd_item ${withDates ? 'adjusted-for-date-title':''}`}>Title</td>
        <td className={`albm_lgnd_item ${withDates ? 'adjusted-for-date-artistname':''}`}>Artists</td>
        {withDates && <td className="albm_lgnd_item">Release</td>}
        <td className="albm_lgnd_item albm_lgnd_itemstrms">Streams</td>
        <td className="albm_lgnd_item albm_lgnd_itemstrms">Downloads</td>
      </tr>
    </table>
  )
}
