import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { PlaylistLayout } from "../layouts/collection/collection"

export const Playlist = ({ props }) => {
    const [{ playlist }, setState ] = useState({ playlist: null, key: null })
    const { id } = useParams()
    const H = useHistory()
    const type = String(H.location.pathname).includes("podcast") ? "podcast": "playlist"

    useEffect(() => { 
        props.analytics.page()
        props.analytics.track(`${type}_view`)
        props.mixpanel.track("page_view")
        if (type === "playlist") props.getPlaylist(id)
        else if (type === "podcast") props.getPodcast({ id, type })
        return props.cleanData
    }, [])

    if (props.data.playlist && !playlist) setState({
        playlist: props.data.playlist, 
        key: Object.keys(props.data.playlist)[0]
    })

    const playlistMeta = playlist ? {
        id,
        title: playlist.playlistName,
        author: "Strma",
        artwork: playlist.artwork,
        tracks: playlist.tracks
    } : {}

    return (
        <PlaylistLayout
            props={props}
            loading={!playlist}
            collection={playlistMeta}
            type='playlist'
        />
    )
}