import React from "react"
import { Link } from "react-router-dom"
import { MobileLibrary } from "./mobileLibrary"
import { CreatePl } from "./mobileLibrary"
import _ from "lodash"
import icon from "./../../assets/playlist.png"
import add from "./../../assets/plus.png"

export const Library = ({ props }) => {
    const Plmarkeup = ({ playlist }) => (
        <Link to={`/u/playlist/${playlist.id}`}>
            <div key={playlist.id} className={`pl_item_user ${ (playlist.playlistName === "My Songs") ? "_mySongsPL":""}` } title={`${playlist.playlistName}`} onClick={() => {}}>
                <img className="pl_icon_user" src={icon} alt=""/>
                <span className="pl_name_user_ playlist">{ playlist.playlistName }</span>
            </div>
        </Link>
    )

    return (
        <React.Fragment>
            <div style={ (props.library) ? { height:"", opacity:"", padding:"" }: { height:"0", padding:"0", opacity:"0" } } className="playlist_Container">
                <h1 className="playlst_cont_header">Your Playlists</h1>
                <div className="playlists_section">
                    {Object.values(props.user.playlists).map((pl, k) => (pl.playlistName) && <Plmarkeup
                        key={k}
                        playlist={{
                            ...pl,
                            id: _.findKey(props.user.playlists, { playlistName: pl.playlistName })
                        }}
                    />)}
                    <div className="create_newpl" id="create_newpl"  onClick={() => {props.toggleCreatePl()}}>
                        <img className="pl_icon_user_create" src={add} alt=""/>
                        <span className="pl_name_user_ playlist">Create Playlist</span>
                    </div>
                </div>
            </div>
            {props.createPlaylistWidget && <CreatePl props={props}/>}
            <MobileLibrary props={props} />
        </React.Fragment>
    )
}