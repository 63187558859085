import React, { useRef } from "react"
import { searchSongStyles } from "./../../constants/constants"

export const SearchSongResults = ({ props }) => {
    const songsContainer = useRef()
    const allSongs = (props.search.data.songs.songTitles) ? { ...props.search.data.songs.songsByArtist, ...props.search.data.songs.songTitles } : props.search.data.songs.songsByArtist

    return (
        <div ref={songsContainer} className='landing-page-singles-container'>
            {
                Object.values(allSongs).map((a, k) => {
                    return (
                        <div key={k} className="landing-page-single-item" style={(a.songTitle === props.PLAY.song.songTitle && a.artist === props.PLAY.song.artist) ?
                            searchSongStyles.container.play : searchSongStyles.container.off 
                        }>
                            <div className="landing-page-single-meta"  onClick={() => props.play(a)}>
                                <div className='landing-page-single-img'>
                                    <img src={`${a.image}`} style={{ height: "100%", width: "100%" }} alt=""/></div>
                                <div className="landing-page-single-meta-text">
                                    <div className="landing-page-single-meta-title">{a.songTitle}</div>
                                    <div className="landing-page-single-meta-artist">{a.artist}</div>
                                </div>
                            </div>
                           
                            <div className="song-small-options-container song-small-options-dots-container" onClick={() => props.toggleOptionsOn(a)}>
                                <p style={ (a.songTitle === props.PLAY.song.songTitle && a.artist === props.PLAY.song.artist) ?
                                     searchSongStyles.options.play  : searchSongStyles.options.off
                                } className="song-small-options-dots-text">...</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}