import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"

export const LandingPageAlbums = ({ props }) => {
  const { albums } = props
  const items = albums ? _.reverse(Object.values(albums)) : albums
  const albumUrl = (song) => `/album/${ _.findKey(albums, { albumTitle: song.albumTitle, Artist: song.Artist })}`
  const albumImgStyles = { height:"100%", width:"100%" }
  return (
      <div>
          {items.map((a, i) => (
              <Link key={i} style={{ cursor:"pointer" }} to={albumUrl(a)}>
                  <div className="albumhomeitemm">
                      <div className="albumitemhm">
                          <img src={`${a.albumArtwork}`} style={albumImgStyles} alt=""/>
                      </div>
                      <div className="album-search-item-meta-title-text">{a.albumTitle}</div>
                      <div className="album-search-item-meta-artist">{a.Artist}</div>
                  </div>
              </Link>
          ))}
      </div>
  )
}