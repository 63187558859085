import React, { useEffect } from "react"
import { connect } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { Nav } from "./nav/nav"
import { MusicPlayer } from "./bottomplayer/bottomplayer"
import { Queue } from "./bottomplayer/queue"
import { Options } from "./options/options"
import { Share } from "./share/share"
import { AddToPlaylistW } from "./options/addtoplaylist"
import { InstallApp } from "./nav/installApp"
import { Footer } from "./footer/footer"
import "../components/css/App.css"
import { AppRoutes } from "./routes/appRoutes"
import { mapReduxActions, mapReduxProps } from "./constants/reduxConstants"
import { updateStreamCountOnSongPlay } from "./helpers"

const App = (props) => { 
    useEffect(() => {
      // if(!props.ads.reg) props.getAds()
      // if(props.ads.showready && _.size(props.ads.reg)> 0) props.showAd()
      updateStreamCountOnSongPlay(props)
    }, [ props.ads.reg, props.PLAY, props.ads.showready, props ])

    useEffect(() => {
      props.resignin()
    }, [])
    
    return (
      <div className="App">
          <Router>             
              <Nav props={props}/>
              <div className="app-body-main">
                  <div className='content-container'>
                    <AppRoutes props={props}/>
                    {props.options.state && <Options props={props} />}
                    {props.share.state && <Share props={props} />}
                    {props.addToPlaylist.state && <AddToPlaylistW props={props} />}
                    <Queue props={props} />
                  </div>
                  <Footer />
              </div>
              <InstallApp props={props}/>
              <MusicPlayer props={props} />
          </Router>
      </div>
    )
}

const mapStateToProps = state => (mapReduxProps(state))

const mapActionsToState = dispatch => (mapReduxActions(dispatch))

export default connect( mapStateToProps, mapActionsToState )(App)