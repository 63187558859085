import { HOME, ALBUM, SHARE, TOGGLEPLAYLIST, 
    ADDTOQUEUE, ADDINGDONE, OPENPLAYLISTSWIDGET, POPULAR, FLIPADS,
    LATEST, CLEAN, PLAYLIST, SINGLE, ARTIST, QUEUE, RAWUPDATE, ADSHOWREADY, ADS,
    SEARCH, LIBRARY, TABS, MQUEUE, CREATEPL, DELETEFROMPL, TOGGLECREATEPL, AD, AD_OFF,
    DELETEPL, NEXT, PREV, READY, PLAY, AUTHENTICATION, SONGSH, SONGSL, ALBUMS, PLAYPAUSE,
    OPEN_QUEUE_STATE, CLOSE_QUEUE_STATE, OPTIONS_OFF, OPTIONS_ON, RE_AUTHENTICATION
} from "../actions/types"
import _, { cloneDeep } from "lodash"
 
export function reducerFunc(state, action) {
   
  switch (action.type) {
        case HOME: {
            const payload = action?.payload?.data?.songs || {}
            const home =  _.reverse(Object.values(payload))
            return { ...state, homeData: action?.payload?.data, raw: home }
        }
        case AUTHENTICATION:
            return { ...state, user: action.payload, library: false }

        case RE_AUTHENTICATION:
            return { ...state, user: action.payload, library: false }
        
        case OPEN_QUEUE_STATE: 
            return { ...state, queueState:true }
        
        case CLOSE_QUEUE_STATE: 
            return { ...state, queueState:false }

        case AD:
            return { ...state, ads: { ...state.ads, status:true, showready:false }}
            
        case ADS:
            return { ...state, ads: { ...state.ads, reg: action.payload }}
        
        case ADSHOWREADY:
            return { ...state, ads: { ...state.ads, showready:true }}
        
        case FLIPADS: {
            let O = [...state.ads.reg]
            let P = O.splice(0, 2)
            let Y = [ ...O, ...P ]
            return { ...state, ads: { ...state.ads, reg: Y }}    
            }
        case AD_OFF: {
            let F = [...state.ads.reg]
            let G = F.splice(0, 1)
            let U = [ ...F, ...G ]
            return { ...state, ads: { ...state.ads, reg:U, status:false }}
            }

        case POPULAR:
            return { ...state, popular: action.payload.data  }
        
        case PLAYPAUSE:
            return { ...state, play:{ ...state.play, paused:!state.play.paused }}

        case ALBUM:{
            let tracks_ =  _.reverse(Object.values(action.payload.data.Tracklist))
            return { ...state, data: { album: action.payload.data }, raw: tracks_ }
        }
        case RAWUPDATE:{
            let list =  Object.values(action.payload)
            return { ...state, raw: list }
        }
        case LATEST:{
            let latest_ = _.reverse(Object.values(action.payload.data))
            return { ...state, latest: action.payload.data, raw: latest_ }
        }
        case SONGSH:{
            let L = _.reverse(Object.values(action.payload.data))
            return { ...state, homeData: { ...state.homeData, songs: action.payload.data }, raw: L }
        }
        case SONGSL:{
            let H = _.reverse(Object.values(action.payload.data))
            return { ...state, latest: { ...state.latest, singles: action.payload.data }, raw: H }
        }
        case ALBUMS:
            return { 
                ...state, 
                latest: {
                    ...state.latest, 
                    albums: action.payload.data
                } 
            }

        case PLAYLIST: {
            const tracks = Object.values(action.payload.data.tracks)
            return { 
                ...state, 
                data: {
                    playlist: action.payload.data 
                }, 
                raw: tracks  
            }
        }
        
        case ARTIST:{
            let allc = [ ...action.payload.data.songs.popular, ...action.payload.data.songs.new ]
            return { ...state, data: { artist: action.payload.data },  raw: allc }
        }
        
        case SINGLE:{
            let sing_ =  _.reverse(Object.values(action.payload.data))
            return { ...state, data: { single: action.payload.data }, raw: sing_ }
        }
        
        case CLEAN:
            return { ...state, data: {}, search:  { data:{ songs:null, artists:null, albums:null }, query:null }}

        case TABS:
            return { ...state, tabs: { 
                ...state.tabs, 
                [state.tabs.current]: false,
                [action.payload.data]: true,
                current: action.payload.data
            }}

        case SEARCH:{
            let searchsongs = Object.values({ ...action.payload.data.results.songs.songTitles, ...action.payload.data.results.songs.songsByArtist })
            return { ...state, search: { ...state.search, query: action.payload.data.query,  data: action.payload.data.results }, raw: searchsongs }
        }
        case ADDTOQUEUE:{
            let music = [ ...state.queue.music, action.payload ]
            return { ...state, queue: { ...state.queue, music: music }, options: { state: false }}
        }
        case OPENPLAYLISTSWIDGET:
            return { 
                    ...state, 
                    addToPlaylist: { state: true, songid: action.payload }, 
                    options: { state: false, id:null }
            }
        
        case DELETEFROMPL:
            return { 
                    ...state, 
                    options: { state: false, id:null },
                    user: { ...state.user, playlists:{ ...state.user.playlists, ...action.payload }}
            }

        case DELETEPL:
            return { 
                ...state, 
                ploptions: { state: false, id:null },
                user: { ...state.user, playlists: action.payload  }
            }

        case CREATEPL:
            return { 
                    ...state, 
                    createplaylist: { state: false, name:null },
                    createPlaylistWidget: false,
                    user: { ...state.user, playlists:{ ...state.user.playlists, ...action.payload.playlist }}
            }

        case TOGGLECREATEPL:
            return { 
                ...state, 
                createPlaylistWidget: !state.createPlaylistWidget
            }

        case ADDINGDONE:
            return { 
                ...state, 
                addToPlaylist: { state: false, songid:null },
                user: { ...state.user, playlists:{ ...state.user.playlists, ...action.payload }}
            }

        case PLAY: {
            const raw = cloneDeep(state.raw)
            const songs = raw[0].songTitle ? raw : _.reverse(Object.values(state.raw[0]))
            const position = _.findKey(songs, { songTitle: action.payload["songTitle"] })
            const songs_after = songs.splice(parseInt(position) + 1, songs.length)
            const songs_before = songs.splice(0, parseInt(position))
            return {
                ...state, 
                queue: { ...state.queue, music: [ action.payload, ...songs_after, ...songs_before ] }, 
                options: { state: false }, 
                play: { ...state.play, paused: false, ready: false, state: true, song: action.payload } 
            }
        }
        
        case NEXT: {
            let q = [...state.queue.music]
            let firstsong = q.splice(0, 1)
            let newq = [ ...q, ...firstsong ]
            return { 
                ...state, 
                play: { ...state.play, ready: true, song:newq[0] },
                queue: { ...state.queue, music:newq } 
            }
        }

        case PREV: {
            let qq = [...state.queue.music]
            let firstsong_prev = qq.splice((qq.length-1), 1)
            let newqq = [ ...firstsong_prev, ...qq ]
            return { 
                ...state, 
                play: { ...state.play, ready: true, song:newqq[0] },
                queue: { ...state.queue, music:newqq } 
            }
        }
        
        case READY:
            return { ...state, play: { ...state.play, ready: true, song: action.payload }}

        case QUEUE:
            return { ...state, queue: { ...state.queue, state:!state.queue.state }}

        case OPTIONS_ON:
            return { ...state, options: { state: !state.options.state, id:action.payload }}

        case OPTIONS_OFF:
            return { ...state, options: { state: false, id: null }}

        case TOGGLEPLAYLIST:
            return { ...state, addToPlaylist: { state: false, songid:null }}

        case SHARE:
            return { 
                ...state, 
                share: { state: !state.share.state, id: action.payload.id, type: action.payload.type },
                options: { state: false, id:null } 
            }

        case MQUEUE:
            return { ...state, mobileQueue: !state.mobileQueue }

        case LIBRARY:
            return { ...state, library: !state.library }
            
        default:
            return state
  }
}