import React, { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { Loader } from "./../loader/loader"

export const SignIn = ({ props }) => { 
    const email = useRef()
    const password = useRef()
    const H = useHistory()
   
    const signin = () => {
        const e = email.current.value
        const p = password.current.value
        if (e && p) props.usersignin({ e, p })
    }

    useEffect(() => {
        if (props.user.id) setTimeout(() => {
            H.push("/library")
        }, 0)
    }, [props])

    return (
        <div className="sign_lgn_backg_cont _loginform_">
            {
                props.user.id ? <Loader text={"Loading your account..."}/>
                :
                <div className="formallcontainer">
                    <div className="_loginform_cont_p">
                        <h1 className="reg_user_hdr_land1_">Sign In</h1>
                        <div className="explain_page_">Log in to access your music library.</div>
                        <div className="reguser_form_cornt_webapp">
                            <input ref={email} type="email" className="reguserfrm_webapp usr_lgn_" placeholder="Username" />
                            <input ref={password} type="password" className="reguserfrm_webapp pss_lgn_" placeholder="Password" />
                        </div>
                        
                        <div className="btn_container_reguser_webapp">
                            <div className="sign_btn_reguser_login _loginbtn_" onClick={signin}>Sign In</div>
                            <div className="clsclosebtn clsmobile" onClick={H.goBack}> 
                                <button className="cls_sgn_lgnn">Close</button> 
                            </div>
                        </div> 
                    </div>
                </div>
            }
            <div className="clsclosebtn clsdesk" onClick={H.goBack}> 
                <button className="cls_sgn_lgn">Close</button> 
            </div>
        </div>
    )
}