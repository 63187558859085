import React from 'react'

export const LazyLoadedAd = () => {
  return (
      <>
        <div className="large-ad-latest-two">
          <div className="ad-container-lazy-load">
            <div className="ad-container-lazy-load-meta-one">
              <div className="ad-logo">AD</div>
              <div className="ad-container-lazy-load-meta-text">Sponsored</div>
            </div>
            <div className="ad-container-lazy-load-meta-two"/>
            <div className="fsa_adtextcontainer_lazy_latest_t">
              <div className="adtextcontent_lazy_latest_t">
                <div className="adtextheader_lazy_latest_t"/>
                <div className="adtextdescription_lazy_latest_t"/>
              </div>
              <a className="adbtnlink_lazy_latest_t">
                <div className="adcalltoaction_lazy_latest_t">Learn More</div>
              </a>
            </div>
          </div>
        </div>
      </>
  )
}
