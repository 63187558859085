
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { MusicContainer } from "./musicContainer"
import { useLatestMusic } from "./hooks"
import { AdContainer } from "../ad/adContainer"
import { LazyLoadedAd } from "../LazyLoaded/LazyLoadedAd"

export const Latest = ({ props }) => {
    const PATH = useParams().tab
    const { singles, albums } = props.latest
    const [ State, setState ] = useState({ lastitem: null })
    const { showMore, lastitem, musicToRender } = useLatestMusic({ props, albums, singles, PATH })
    const stylesByAgentDevice = navigator.userAgent.includes("iPhone") ? { width:"100%" } : null
    const singlesLinkClasses = `latest-music-tab latest-music-tab-songs ${PATH === "singles" ? "latest-music-tab-active" : ""}`
    const albumsLinkClasses = `latest-music-tab latest-music-tab-albums ${PATH === "albums" ? "latest-music-tab-active" : ""}`
    
    if (singles && State.lastitem !== lastitem) setState({ lastitem })
    
    const ads = () => (props.ads.reg ? 
        <AdContainer props={props}/> : <LazyLoadedAd/>
    )

    useEffect(() => {
        props.getLatest()
    }, [])
    
    return (
        <div className="new_release_cont_ navpage">
            <div className="fadehome" />
            <div style={stylesByAgentDevice}
                className="newreleasewidecont">
                <h1 className="nw_cont_hdr">New Releases</h1>
                <div className="newrelease_tabs">
                    <Link to='/latest/singles'>
                        <button className={singlesLinkClasses}>Songs</button>
                    </Link>
                    <Link to='/latest/albums'>
                        <button className={albumsLinkClasses}>Albums</button>
                    </Link>
                </div>
                <MusicContainer
                    props={props}
                    type={PATH}
                    music={musicToRender()}
                />
                <div className="latest-showmore-container">
                    <button 
                        className="showmore" 
                        onClick={showMore}
                   >Show More</button>
                </div>
            </div>
            <div className="latest-right-ad-placement">{ads()}</div>
        </div>
    )
}