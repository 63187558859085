import React, { useEffect, useState } from "react"
import { Owl } from "../owl"
import $ from "jquery"

export const QueueArtworkSection = ({ props }) => {
  const [ State, setState ] = useState({
      CarouselInitialized: false,
      queue: null
  })
  const InitializeOwlCarousel = () => {
      try {
          // Initialize Owl Script
          Owl()
          const owl = $(".owl-carousel")
          const totalTracks = Object.values(props.queue.music).length
          const totalNodes = document.querySelectorAll(".item").length

          // If tracks Are aplaying and images are loaded
          if (!State.CarouselInitialized && totalTracks > 0 && totalNodes === totalTracks) {
              // Initialize Owl Carousel
              owl.owlCarousel({ dots: false, items: 1, loop: false })
              // Save Swipe Index
              sessionStorage.setItem("swipeIndex", 0)
              // Set Event Listener
              owl.on("changed.owl.carousel", (event) => {
                  const currentIndex = parseInt(sessionStorage.getItem("swipeIndex"))
                  const item = document.querySelectorAll(".item")[event.item.index]
                  const nodeIndex = parseInt(item.dataset.index)
                  const isRight = nodeIndex > currentIndex ? true : false
                  const isLeft = nodeIndex < currentIndex ? true : false

                  // Update Swipe Index   
                  sessionStorage.setItem("swipeIndex", nodeIndex)

                  if (isLeft) props.PREV()
                  if (isRight) props.NEXT()
              })
              // Set CarouselInitialized State
              setState({ ...State, CarouselInitialized: true })
          }
      } catch (e) {
          props.mixpanel.track("error", {
              type: "queue_artwork", message: e.message
          })
      }
  }

  // 1. Initial Mount
  useEffect(() => {
    setState({ ...State, queue: props.queue.music })
  }, [])

  // 2. Queue Length Change
  useEffect(() => {
      setState({ queue: null, CarouselInitialized: false })
  }, [Object.values(props.queue.music).length])


  // 3. Queue State Change
  useEffect(() => {
      window.kudz = InitializeOwlCarousel
      if (!State.queue) setState({ ...State, queue: props.queue.music })
      if (State.queue && !State.CarouselInitialized) InitializeOwlCarousel()
  }, [State.queue])

  return (
      <React.Fragment>
          <div className="toggle_meta_cont">
              {
                  State.queue ?
                      <div className="owl-carousel">
                          {
                              Object.values(State.queue).map((song, key) => (
                                  <div className="item" key={key} data-id={song.id} data-index={key}>
                                      <div className="img_toggle_cont">
                                          <img src={song.image} className="toggle_songimg_" alt="toggle_songimg_"/>
                                      </div>
                                  </div>
                              ))
                          }
                      </div>
                      :
                      null
              }
          </div>
      </React.Fragment>
  )
}
