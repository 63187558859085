import React from 'react'
import { Link } from 'react-router-dom'

export const LandingPageLazyLoadedAlbums = () => {
  const items = [ ...Array(8) ]
  const homeLazyloadedAlbumStyles = { 
      background:'rgb(31, 27, 27)', 
      width:'100px', 
      height: '15px', 
      margin:'10px auto'
  }
  return (
      <div>
          {items.map((a,i) => (
              <Link key={i} style={{ cursor:'pointer' }} to='/'>
                  <div className="landing-page-playlist-item">
                      <div className="landing-page-playlist-square"/>
                      <div style={homeLazyloadedAlbumStyles}/>
                  </div>
              </Link>
          ))}
      </div>
  )
}