import React from "react"
import note from "../../assets/note.png"

export const Queue = ({ props }) => {
    const containerStyleOptions = {
        top: props?.queue?.state ? "" : "100vh"
    }
    return (
        <div style={containerStyleOptions} className="q_cont_sngs">
            <div className="queuein">
                <section className="q_hdr_">
                    <span>Queue Playlist</span>
                </section>
                <section className="q_cnt_main">
                    {
                        props.queue.music.map((a, k) => {
                            if (a.id === props.queue.music[0].id) {
                                return (
                                    <div key={k} className="q_item_song_ q currentlyplaying" onClick={() => props.play(a)}>
                                        <img className="queuemainsngplayimg" src={a.image} alt=""/>
                                        <div className="queuemainplsng">
                                            <div className="queuemetahold">
                                                <span className="songnme_q song-play-name qp">
                                                    <div className="sng_holder nowplayngtext qp">{a.songTitle}</div>
                                                </span>
                                                <span className="artistnme_q song-play-artist qp">
                                                    <div className="sng_holder qp">{a.artist}</div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else return (
                                <div key={k} className='q_item_song_ q' onClick={() => props.play(a)}>
                                    <img src={note} className="notequeue_sng_itm" alt=""/>
                                    <div className="queuemetahold">
                                        <span className="songnme_q song-play-name"><div className={"sng_holder"}>{a.songTitle}</div></span>
                                        <span className="artistnme_q song-play-artist"><div className="sng_holder">{a.artist}</div></span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </section>
            </div>
        </div>
    )
}