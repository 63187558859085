import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { mobileNavDefaultState } from "../constants/constants"

export const MobileNav = ({ props }) => {
    const [ State, setState ] = useState(mobileNavDefaultState)
    const PATH = useHistory().location.pathname.replace("/", "")

    const navbtn = (e) => setState({ 
        ...State, 
        navbtn: { 
            ...State.navbtn,  
            [State.navbtn.current]: false, 
            [e]: true, current:e
        }
    })

    // On Reload:
    useEffect(() => {
        const page = (PATH === false || PATH === "/") ? "home" : PATH
        setState({ ...State, navbtn: { ...State.navbtn,  [State.navbtn.current]:false, [page]:true, current:page }})
    }, [PATH])

    return (
        <React.Fragment> 
            <div className="nav-inner">
                <Link to='/'> 
                    <span className='home_mbl nav_itm_mob'  onClick={() => navbtn("home")}>
                        <svg className="homeicon icnselct" viewBox="0 0 24 24">
                            <path className={`homeiconpath ${(State.navbtn.home) ? "homeiconactive" : ""}`} d="M2 12L5 9.3M22 12L19 9.3M19 9.3L12 3L5 9.3M19 9.3V21H5V9.3" />
                        </svg>
                        <span className={`btn_title_mbl home_btn_mob ${(State.navbtn.home) ? "btn_title_mblactive":""}`}>Home</span>
                    </span>
                </Link>
                <Link to='/latest/singles'>
                    <span className='latest_mbl nav_itm_mob' onClick={() => navbtn("latest")}>
                        <svg className="menu_icn icnselct" viewBox="0 0 96.25 95">
                            <ellipse className={`cls-1 ${(State.navbtn.latest) ? "ellipseactive":""}`} cx="48.12" cy="47.5" rx="44.62" ry="44" />
                            <ellipse className={`cls-2 ellipse ${(State.navbtn.latest) ? "ellipseactive":""}`} cx="48.12" cy="47" rx="10.82" ry="10.71"/>
                        </svg>
                        <span className={`btn_title_mbl search_btn_mob ${(State.navbtn.latest) ? "btn_title_mblactive" : ""}`}>Latest</span>
                    </span>
                </Link>
                <Link to='/search'>
                    <span className='search_mbl nav_itm_mob'  onClick={() => navbtn("search")}>
                        <svg className="menu_icn icnselct" viewBox="0 0 90.96 90.06">
                            <ellipse  className={`cls-1 ${(State.navbtn.search) ? "ellipseactive":""}`} cx="38.91" cy="38.22" rx="36.41" ry="35.72"></ellipse>
                            <path className="cls-2" d="M577,992.45a27.87,27.87,0,0,0,7.12-6.75l17.25,15.78s-.49,6.71-6.69,7.52Z" transform="translate(-510.45 -918.94)"></path>
                        </svg>
                        <span className={`btn_title_mbl search_btn_mob ${(State.navbtn.search) ? "btn_title_mblactive":""}`}>Search</span>
                    </span>
                </Link>
                <Link to={`${(props.user.id) ? "/library" : "/m/auth"}`}>
                    <span className={"search_mbl nav_itm_mob"} onClick={() => navbtn("search")}>
                        <div className="menu_icn icnselct">
                            <div className="icnhol">
                                <div className={`incline ${(State.navbtn.library) ? "inclineactive":""}`}/>
                                <div className={`incline ${(State.navbtn.library) ? "inclineactive":""}`}/>
                                <div className={`incline lnrte ${(State.navbtn.library) ? "inclineactive":""}`}/>
                            </div>
                        </div>
                        <span className={`btn_title_mbl search_btn_mob ${(State.navbtn.library) ? "btn_title_mblactive":""}`}  
                            onClick={() => navbtn("library")}>Library</span>
                    </span>
                </Link>
            </div> 
        </React.Fragment>
    )
}