import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { SongContainer } from "../album/songMarkup"
import { BackArrow } from "../helpers/backArrow"
import { TracklistLegend } from "../tracklistLegend/tracklistLegend"

export const SinglesPage = ({ props }) => {
  const P = useParams()
  const artist = props?.data?.artist
  const singles = artist ? artist?.songs?.new : []
  
  const renderedSongs = () => singles.map((sng, index) => ( 
    <SongContainer 
      key={index} 
      song={sng} 
      object={""} 
      props={props} 
      type={"amsong"}
      withDates={true}
    />
  ))

  useEffect(() => {
    props.getArtist(P.id)
    // Track Page 
    props.analytics.page()
    /* Track a custom event */
    props.mixpanel.track("page_view")
    props.analytics.track("singles_view")
  }, [])

  console.log({ artist })

  return (
    <div>
      <div className="asoverlay">
          <div className="ascontainer">
              <div className="asheader">
                {/* <button className="playlist-container-close-page" onClick={H.goBack}>Close</button> */}
                <BackArrow />
                <div className='singles-header-meta'>
                  <div>
                    <div className='singles-artist-artwork'>
                    <img src={artist?.artist?.profileImageUrl}
                      alt="" height="100%" width="100%" />
                    </div>
                    <div className='singles-artist-text'>
                      <div className="ashdheader">{artist?.artist?.artistName}</div>
                      <div className="ashdpara">All their songs arranged by date</div>
                      <div className="singles-track-count">{singles.length} songs</div>
                    </div>
                  </div>
                </div>
                <div className="asbordersep" />
                <TracklistLegend withDates={true}/>
              </div>
              <div className="assongsection">
                {renderedSongs()}
              </div>
          </div>
      </div>
    </div>
  )
}
