
import React from 'react'
import { Link } from 'react-router-dom'

export const LazyLoadedLandingPagePlaylists = () => {
    const items = [ ...Array(8) ]
    const style = {
        background:'rgb(31, 27, 27)', 
        fontWeight:800, 
        width:'100px', 
        height: '15px', 
        margin:'10px auto'
    }

    return (
        <div>
            {items.map((a,i) => (
                <Link key={i} style={{ cursor: 'pointer' }} to={`/playlist/${i}`}>
                    <div className="landing-page-playlist-item">
                        <div className="landing-page-playlist-square"/>
                        <div style={style}/>
                    </div>
                </Link>
            ))}
        </div>
    )
}
