import React, { useState, useEffect, useRef } from "react"
import { BottomM } from "./mobile/bottommobile"
import { playSongHandler, useMusicPlayer } from "./helper"
import { musicPlayerInitState } from "../constants/constants"
import { MobileQueue } from "./mobile/mobileQueue"
import { DesktopPlayerQueueSection } from "./desktop/DesktopPlayerQueueSection"
import { PlayerMusicControls } from "./desktop/PlayerMusicControls"
import { BottomPlayerSongMeta } from "./desktop/BottomPlayerSongMeta"

export const MusicPlayer = ({ props }) => {
    const audioPlayerRefElements = [...Array(5)].map(useRef)
    const [ State, setState ] = useState(musicPlayerInitState)

    const {
        changePlayState,
        PlayPauseButton,
        IteratorButton,
        switchToPreviousSong,
        switchToNextSong,
        durationBar,
        SongTitle,
        ArtistName,
        SongMetaClassName,
        time, slider, duration, audio, durationhold, timer, currentTime
    } = useMusicPlayer({ props, audioPlayerRefElements })
    
    useEffect(() => {
        changePlayState({ State, setState })
    }, [props.PLAY])

    playSongHandler(duration, time, slider, audio, timer, State, setState, props)

    return (<>
        <div>
            <div className="bottom-music-player-container">
                <BottomPlayerSongMeta props={props}
                    SongMetaClassName={SongMetaClassName}
                    ArtistName={ArtistName}
                    SongTitle={SongTitle}
                />
                <PlayerMusicControls
                    time={time}
                    slider={slider}
                    duration={duration}
                    durationhold={durationhold}
                    switchToPreviousSong={switchToPreviousSong}
                    switchToNextSong={switchToNextSong}
                    PlayPauseButton={PlayPauseButton}
                    IteratorButton={IteratorButton}
                    durationBar={durationBar}
                />
                <DesktopPlayerQueueSection
                    props={props}
                    audio={audio}
                />
            </div>
            <BottomM props={props}/>
        </div>
        <MobileQueue props={props} time={time}
            slider={slider} audio={audio}
            duration={duration}
            currentTime={currentTime}
        />
    </>)
}