import React from "react"
import musicPlayerInitPicture from "./../../../assets/blcko.png"

export const BottomPlayerSongMeta = ({ props, SongTitle, ArtistName, SongMetaClassName }) => {
  return <div className="song_metacontainer_">
      <div className="songMeta">
          <div className="metapic">
              <img src={(props.queue.music[0]) ?
                  props.queue.music[0].image : musicPlayerInitPicture}
                  alt="meta_src_pc"
                  className="meta_src_pc" />
          </div>
          <div className="metaText">
              <div className="metaFieldHold">
                  <div className={`songName_meta ${SongMetaClassName}`}>{SongTitle}</div>
              </div>
              <div className="metaFieldHold">
                  <div className={`artistName_meta ${SongMetaClassName}`}>{ArtistName}</div>
              </div>
          </div>
      </div>
  </div>
}