import _ from "lodash"
import React from "react"
import $ from "jquery"

export const GenerateUserPlaylistCover = (props, playlist) => props.user.playlists ? () => {
  const count = _.size(playlist.songs) >= 4 ? 4 : 1
  return <div className="imgcollage">{
      [...Array(count)].map((a, i) => a.image && (
          <div key={i} className={`imgcollc ${count < 4 ? "imgcollcone" : "" }`}>
              <img src={a.image} className="imgcollg" alt="imgcollg"/>
          </div>
  ))}</div>
} : () => ""

export const updateStreamCountOnSongPlay = (props) => {
  if (props.PLAY.state && props.PLAY.paused === false) {
    const StreamListener = setInterval(() => {
      try {
        const audio = $(".aud")[0]
        const id = props.queue.music[0].id
        if (audio.currentTime >= 15) {
          clearInterval(StreamListener)
          props.updateMetric(id, "s")
        }
      } catch (e) {
        console.log(e.message)
      }
    }, 1000)
  }
}