import React, { useEffect, useState } from "react"
import { Playlists } from "./playlists"
import { Albums } from "./albums"
import { Singles } from "./singles"
import { Helmet } from "react-helmet"

export const Home = ({ props }) => {
    const { playlists, songs, albums } = props.home
    const [{ lastitem: { home }}, setState ] = useState({
        lastitem: { home: null }
    })
    
    useEffect(() => {
        document.title = "Strma - Web Player"
        props.home()
        props.mixpanel.track("home_view")
    }, [])

    if (playlists && songs && albums && !home) {
        setState({ lastitem: Object.values(songs || {})
            .sort((x, y) => y["timeStamp"] - x["timeStamp"])[
                Object.values(songs).length-1
        ] })
    }

    return (
        <React.Fragment>
            <div className="fadehome"/>
            <div className="home-container">
                <Helmet>
                    <meta className="metadescript" name="description" content="Start streaming now in your browser. Latest tracks, search and add songs to your playlists." />
                    <meta className="metakeywords" name="keywords" content="Strma, zimbabwe, music, online, listen, streaming, play, digital, album, artist, playlist, strmaio, strma.app, web player, zim-music" />
                    <meta className="metaurl" property="og:url" content="https://player.strma.app" />
                    <meta className="metaogtitle" property="og:title" content="Music for everyone" />
                    <meta className="metaogdescription" property="og:description" content="Listen to your favourite Zimbabwean music with Strma." />
                    <meta className="metaogimage" property="og:image"content="./../../assets/feature.png"/>
                    <meta className="metaogtype" property="og:type"content="website" />
                    <meta className="metaogid" property="fb:app_id" content="605160483224778"/>
                </Helmet>
                <Singles props={props} />
                <Playlists props={props.homeData} />
                <Albums props={props.homeData} />
            </div>
        </React.Fragment>
    )
}