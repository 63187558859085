import React from 'react'

export const MusicBars = () => {
  return (
    <div className='musicbars'>
      <span className="musicbarspan" />
      <span className="musicbarspan" />
      <span className="musicbarspan" />
    </div>
  )
}