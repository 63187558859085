import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { reducerFunc } from "./../reducer/reducer"
import { analytics, mixpanel } from "./../analytics"
import { initialState } from "../constants/constants"

export const Store = createStore(
    reducerFunc,
    initialState({
        analytics,
        mixpanel
    }),
    applyMiddleware(...[thunk.withExtraArgument({ DB: null })])
)