import React, { useRef } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"
import R from "./../../assets/playlistPink.png"
import L from "./../../assets/right.png"
import add from "./../../assets/plus.png"

const Playlists = ({ props }) => {
    return (
        <div className="library_playlist_contM">
        {props.user.playlists && Object.values(props.user.playlists).map((a, k) => (a.playlistName) && (
            <Link key={k} to={`/u/playlist/${ _.findKey(props.user.playlists, { playlistName:a.playlistName }) }`}>
                <div className="pl_item_mobile">
                    <img className="pl_item_icon" src={R} alt=""/>
                    <span className="pl_item_meta">
                        <span className="pl_item_pl_name playlist">{a.playlistName}</span>
                        <span className="pl_item_numsongs">{Object.values(a.songs).length} songs</span>
                    </span>
                    <span className="pl_item_arrIcon_cnt">
                        <img className="pl_item_arrIcon" src={L} alt=""/>
                    </span>
                </div>
            </Link>
        ))}
        </div>
    )
}

export const CreatePl = ({ props }) => {
    const NameField = useRef()
    
    const Submit = () => {
        const Name = NameField.current.value
        if (_.size(Name)> 0) props.createPlaylist({
            uid: props.user.id, 
            plname:Name
        })
        else alert("Please enter Playlist name.")
    }

    return (
        <div className="contextoverlypl">
            <div className="contextoverlyhold">
                <div style={{  background: "#333131", borderTop: "solid 3px #c62a2a" }} className="context_menu">
                    <div className="context_menu_hdr">Create New Playlist</div>
                    <div className="ctx_playlistitm_hld" style={{ height:"unset" }}>
                        <input ref={NameField} className="createplnamefld" placeholder="Give your playlist a name" />
                        <button className="createplbtncrt" onClick={Submit}>Create</button>
                    </div>
                    <div className="closectxhold" onClick={props.toggleCreatePl}><span className="ctxclosepltxt">Close</span></div>
                </div>
            </div>
        </div>
    )
}

export const MobileLibrary = ({ props }) => {
    return (
        <div className="librarypage_mob">
            <div className="library_header_contM">
                <h1 className="library_hdr_small">Music Library</h1>
                <div className="library_hdr_lg">Your Collection of Playlists</div>
                <div className="createplbtn" onClick={props.toggleCreatePl}>
                    <img className="pl_icon_user_createpl" src={add} alt=""/>
                    <span className="pl_name_user_playlist">Create Playlist</span>
                </div>
            </div>
            <Playlists props={props}/>{
                props.createPlaylistWidget && <CreatePl props={props}/> 
            }
        </div>
    )
}
