import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { SearchMobile } from "./searchmobile"
import { DesktopSearchResults } from "./results/desktopSearchResults"
import { GenerateMetaTags } from "./../generateMetaTags/generateMetaTags"

export const Search = ({ props }) => {
    const params = useParams()
    useEffect(() => {
        props.mixpanel.track("user_search")
        if (params.query && props.search.query) props.cleanData()
    }, [])
    
    if (params.query && params.query !== props.search.query && params.query) props.SEARCH(params.query)

    return (
        <div>
            {GenerateMetaTags("search")}
            <DesktopSearchResults props={props}/>
            <SearchMobile props={props}/>
        </div>
    )
}
