import React, { useEffect } from "react"
import { useAdSequences } from "../latest/hooks"
import { LazyLoadedAd } from "./../LazyLoaded/LazyLoadedAd"
import { RightAdTemplate } from "./rightAdTemplate"

export const AdContainer = ({ props }) => {
  const [ ad1, ad2 ] = props.ads.reg
  const { flipAds } = useAdSequences({ props })

  useEffect(() => {
    flipAds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ads.reg])

  return (
    <div>
      {props.ads.reg ? 
        <>
            <RightAdTemplate props={props} ad={ad1}/>
            <RightAdTemplate props={props} ad={ad2}/>
        </>
        :<LazyLoadedAd />
      }
    </div>
  )
}