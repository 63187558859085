import React from 'react';
import logo from "./../../assets/logo.png";

export const InstallApp = ({ props }) => {
    return (
        <div className="install-app">
            <div className="install-app-inn">
                <div className="leftinstall">
                    <img src={logo} alt="" className="installicon"/>
                </div>
                <a href="https://play.google.com/store/apps/details?id=io.strma.music&hl=en_GB" target="blank"><div className="rightinstall">Use Strma App</div></a>
            </div>            
        </div>
    )
}
