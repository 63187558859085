import React from 'react'
import { SongContainer } from './../../album/songMarkup'

export const SongsResults = ({ props }) => {
    const { songTitles, songsByArtist } = props.search.data.songs
    const allSongs = songTitles ? { ...songsByArtist, ...songTitles } : songsByArtist
    const songs = Object.values(allSongs).splice(0,10)
    return (
        <div>
            {songs.map((song, i) => (
                <SongContainer 
                    key={i}
                    song={song} 
                    props={props} 
                    additionalClasses='search-songs'
                />
            ))}
        </div>
    )
}