import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { GenerateUserPlaylistCover } from "../helpers"
import { PlaylistLayout } from "../layouts/collection/collection"

export const UserPlaylist = ({ props }) => {
    const { id } = useParams()
    const playlist = props.user.playlists[id] || null

    useEffect(() => {
        props.analytics.page()
        props.analytics.track("user_playlist_view")
        props.mixpanel.track("page_view")
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.user.playlists) props.rawsongupdate(props.user.playlists[id].songs)
    }, [ id, props, props.user.playlists ])

    const playlistMeta = playlist ? {
        id,
        title: playlist.playlistName,
        author: "You",
        artwork: () => GenerateUserPlaylistCover(props, playlist),
        tracks: playlist.songs
    } : {}

    return (
        <PlaylistLayout
            props={props}
            loading={!playlist}
            collection={playlistMeta}
            type='userPlaylist'
        />
    )
}