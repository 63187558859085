import _ from "lodash"
import React from "react"
import { homeSinglesLazyLoadStyles } from "../constants/constants"

export const LandingPageSingles = ({ props }) => {
  const { off, play } = homeSinglesLazyLoadStyles.container
  const { songTitle, artist } = props.PLAY.song
  const songArtworkStyles = { height:"100%", width:"100%" }
  const songContainerStyles = ({ songTitle: song_title, artist: songArtist }) => song_title === songTitle && songArtist === artist ? play : off 
  const items = _.reverse(Object.values(props.homeData.songs))

  return (
      <div className='landing-page-singles-container'>
          {items.map((a, k) => (
              <div key={k} className="landing-page-single-item" style={songContainerStyles(a)}>
                  <div className="landing-page-single-meta"  onClick={() => props.play(a)}>
                      <div className='landing-page-single-img'>
                          <img src={a.image} style={songArtworkStyles} alt=""/>
                      </div>
                      <div className="landing-page-single-meta-text">
                          <div className="landing-page-single-meta-title">{a.songTitle}</div>
                          <div className="landing-page-single-meta-artist">{a.artist}</div>
                      </div>
                  </div>
                  <div className="song-small-options-container song-small-options-dots-container" 
                    onClick={() => props.toggleOptionsOn(a, "single")}>
                      <p className="song-small-options-dots-text">...</p>
                  </div>
              </div>
          ))}
      </div>
  )
}