import React from "react"
import _ from "lodash"
import { Link } from "react-router-dom"

export const AlbumsResults = ({ props }) => {
  const allAlbums = (props.search.data.albums.albumTitles) ? {
      ...props.search.data.albums.albumTitles, 
      ...props.search.data.albums.byArtist
  } : props.search.data.albums.byArtist

  const albums = allAlbums ? Object.values(allAlbums).splice(0, 20) : []

  return (
      <div className="albums-search-result-items">
          {allAlbums && albums.map((album, k) => (
              <Link key={k} className="album-search-item" to={`/album/${ _.findKey(allAlbums, { Artist:album.Artist, albumTitle: album.albumTitle })}`}>
                  <img src={`${album.albumArtwork}`} className="album-search-item-img" alt=""/>
                  <div className="album-search-item-meta">
                      <div className="album-search-item-meta-title">
                          <div className="album-search-item-meta-title-text">{album.albumTitle}</div>
                      </div>
                      <div className="album-search-item-meta-artist">{ album.Artist}</div>
                  </div>
              </Link> 
          ))}
      </div>
  )
}