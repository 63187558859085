import React from "react"
import { Link } from "react-router-dom"
import _ from "lodash"

export const ArtistResults = ({ props }) => {
  const artistImage = (profileImageUrl) => profileImageUrl === "default.png" ? 
    <div className='artist-img-placeholder'/>
    :
    <img src={profileImageUrl} alt="art_res_item_img_" className="art_res_item_img_" /> 
  return (
    <div className="artists-search-result-items">
        {props.search.data.artists.artistName && (
            Object.values(props.search.data.artists.artistName).splice(0, 6).map(( a, i) => (
            <Link key={i} to={`/artist/${_.findKey(props.search.data.artists.artistName, { artistName: a.artistName })}`}>
                <div className="artist-result-search-item" >
                    <div className='artist-result-img-container'>
                     {artistImage(a.profileImageUrl)}
                    </div>
                    <span className="art_res_item_name_ artist">{a.artistName}</span>
                </div>
            </Link>
        )))}
    </div>
  )
}
