import _ from "lodash"
import React from "react"
import { useHistory } from "react-router-dom"
import { SongContainer } from "../../album/songMarkup"
import { BackArrow } from "../../helpers/backArrow"
import { TracklistLegend } from "./../../tracklistLegend/tracklistLegend"

export const PlaylistLayout = ({ props, collection, type, loading }) => {
    const { title, author, artwork, id, tracks } = collection
    const H = useHistory()

    const getArtist = () => {
        // get artist on click if album
    }

    const renderSongs = () => collection && Object.values(tracks).map((a, i) => (
      <SongContainer
        key={i} 
        song={a} 
        object={""} 
        props={props} 
        type={"amsong"}
      />
    ))
    
    return (
        <div className="playlist-container">
            <div className="playlist-meta-container">
                <button className="playlist-container-close-page" onClick={H.goBack}>Close</button>
                <BackArrow />
                <div className="playlist-artwork-container playlist-artwork-container-second-class">
                    {type !== "userPlaylist" ? (
                    !loading ? <img 
                        className="playlist-artwork-container-img" 
                        src={artwork}
                        alt="albumArtwork"
                    /> : <div className='playlist-artwork-container-img-lazy' />)
                    : artwork()
                }
                </div>
                <div className="playlist-container-title">{title || ""}</div>
                <div className="playlist-container-artist" onClick={getArtist}>{`By ${author}` || "" }</div>
                <div className="playlist-container-song-count">{_.size(tracks || [])} Songs</div>
                {type !== "userPlaylist" && <div 
                    className="shareobjectbutton" 
                    onClick={() => props.toggleShare(id, type)}
               >Share {type}</div>}
            </div>
            <div className="playlist-container-tracklist">
                <TracklistLegend/>
                <div className="playlist-container-tracklist-body">{!loading && renderSongs()}</div>
            </div>
        </div>
    )
}
